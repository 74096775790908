import { Component } from '@angular/core'
import { HomeService } from '../../services/home.service'
import { Router } from '@angular/router'

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  trendingNews: any = []

  constructor(
    public _homeServices: HomeService,
    public router: Router,
  ) {}

  ngOnInit(): void {
    this.getTrendingNews()
  }

  getTrendingNews() {
    this._homeServices.getTrendingNews().subscribe({
      next: (res: any) => {
        if (res.success === true) {
          this.trendingNews = res.data
        }
      },
      error: (err) => {
        console.log('error', err)
      },
    })
  }

  // gotoNewsDetail(newsId: any) {
  //   this.router.navigate(['news-detail', { _id: newsId }])
  // }

  gotoNewsDetail(newsId: any, newsTitle: string) {
    this.router.navigate(['news-detail', this.formatTitleForUrl(newsTitle), newsId])
  }

  formatTitleForUrl(title: string): string {
    // Remove spaces and convert to lowercase, you can add more logic based on your requirements
    return title.replace(/\s+/g, '-').toLowerCase()
  }

  gotoAboutUs(){
    this.router.navigateByUrl('about-us');
  }
}
