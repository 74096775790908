import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  public isLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
  public userName: BehaviorSubject<string> = new BehaviorSubject<string>('')

  constructor() {}

  show(){
    this.isLoading.next(true);
  }

  hide(){
    this.isLoading.next(false);
  }
}
