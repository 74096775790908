<div class="container">
  <h5></h5>
  <hr />
  <div class="row">
    <div class="col-sm-12 col-lg-9 col-md-12 border">
      <div class="d-flex align-items-center my-3">
        <div class="flex-shrink-0 view-profile-img">
          <img src="{{ profileNews?.user?.avatar[0]?.imageUrl ? profileNews?.user?.avatar[0]?.imageUrl : 'assets/image/user-img.avif' }}" alt="..." width="100%" />
        </div>
        <div class="flex-grow-1 ms-3">
          <h5 class="fw-bold">{{profileNews?.user?.firstName}} {{profileNews?.user?.lastName}}</h5>
          <p class="m-0">{{profileNews?.user?.designation}}</p>
          <p class="m-0">{{profileNews?.user?.description}}</p>
          <!-- <p>{{profileNews?.user?.email}}</p> -->
        </div>
      </div>
    </div>
  </div>
  <div class="row my-2">
    <div class="col-sm-12 col-lg-9 col-md-12">
      <div *ngFor="let allNews of  profileNews?.news; let i = index">
        <div class="d-flex align-items-center my-3 topic" (click)="gotoNewsDetail(allNews?._id, allNews?.title)">
          <div class="flex-shrink-0 view-img">
            <img src="{{ allNews?.images[0]?.imageUrl ? allNews?.images[0]?.imageUrl : 'assets/image/no-image.png' }}" alt="..." width="100%" />
          </div>
          <div class="flex-grow-1 ms-3">
            <h5 class="fw-bold mob-head">
              {{allNews?.title}}
            </h5>
            <p class="mob-content" [innerHTML]="(allNews?.content | slice: 0 : 100) + (allNews?.content.length > 100 ? '...' : '')"></p>
            <p class="time">
              <i class="fa fa-clock-o" aria-hidden="true"> </i>&nbsp; 
              {{ allNews?.createdAt | date: 'MMMM d,h:mma' }} &nbsp;By {{ allNews?.createdBy?.firstName}} {{profileNews?.user?.lastName}}
            </p>
          </div>
        </div>
        <hr>
      </div>
      <nav aria-label="Page navigation" *ngIf="this.totalPages">
        <ul class="pagination justify-content-center mt-4">
          <li class="page-item" [class.disabled]="currentPage === 1">
            <a class="page-link" (click)="prevPage()" tabindex="-1" [attr.aria-disabled]="currentPage === 1 ? true : null">Previous</a>
          </li>
          <li class="page-item" *ngFor="let page of pages" [class.active]="page === currentPage">
            <a class="page-link" (click)="setPage(page)">{{ page }}</a>
          </li>
          <li class="page-item" [class.disabled]="currentPage === totalPages">
            <a class="page-link" (click)="nextPage()" [attr.aria-disabled]="currentPage === totalPages ? true : null">Next</a>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</div>
