import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'south-asian'
  isScriptLoaded = false
  constructor(public route: Router){}
  ngOnInit(): void {
    // if (!this.isScriptLoaded) {
    //   const tag = document.createElement('script')
    //   tag.src = `<script src="https://www.youtube.com/iframe_api"></script>`
    //   document.body.appendChild(tag)
    //   this.isScriptLoaded = true
    // }

  }

  // onActivate(event:any){
  //     window.scroll({ 
  //       top: 0, 
  //       left: 0, 
  //       behavior: 'smooth' 
  // });
  // }
}
