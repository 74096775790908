import { Component } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LoaderService } from 'src/app/shared/services/loader.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent {

  loading!:BehaviorSubject<boolean>

  constructor(public loadingService: LoaderService){
    this.loading = this.loadingService.isLoading
  }

}
