import { Component } from '@angular/core'
import { FormBuilder, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { HomeService } from 'src/app/shared/services/home.service'
import { LoaderService } from 'src/app/shared/services/loader.service'
import Swal from 'sweetalert2'
@Component({
  selector: 'app-view-more',
  templateUrl: './view-more.component.html',
  styleUrls: ['./view-more.component.scss'],
  host: {ngSkipHydration: 'true'},
})
export class ViewMoreComponent {
  type: any = ''
  categoryName: any = ''
  allNews: any = []
  subCategory: any = ''
  newsLetterForm : any;
  signedUp : boolean = false;
  userName: string =''

  currentPage: number = 1; // Current page
  itemsPerPage: number = 5; // Number of items per page
  totalPages: number = 0; // Total number of pages
  pages: number[] = [];

  constructor(
    public route: ActivatedRoute,
    public _homeService: HomeService,
    public router: Router,
    private _fb : FormBuilder,
    public loaderService: LoaderService,
  ) {
    this.newsLetterForm = this._fb.group({
      email : ['',[ Validators.required, Validators.email]]
    })
  }

  ngOnInit() {
    this.route.paramMap.subscribe({
      next: (params) => {
        if (params.get('categoryName')) {
          this.categoryName = params.get('categoryName')
        } else {
          this.categoryName = ''
        }
        if (params.get('subCategory')) {
          this.subCategory = params.get('subCategory')
        } else {
          this.subCategory = ''
        }
        if (this.categoryName) {
          this.getNews(this.currentPage, this.itemsPerPage,this.categoryName)
        } else if (this.subCategory) {
          this.getNews(this.currentPage, this.itemsPerPage,'', this.subCategory)
        }
      },
    })

    this.loaderService.userName.subscribe(name=>{
      this.userName = name
    })
  }

  getNews(pageNumber:any, pageSize:any,categoryName?: string, subCategory?: string) {
    categoryName = categoryName ? categoryName : ''
    subCategory = subCategory ? subCategory : ''
    this._homeService.getNewsByTypeAndCategory(pageNumber, pageSize,categoryName, subCategory).subscribe({
      next: (res) => {
        this.allNews = res.data
        this.totalPages = Math.ceil(res.count / this.itemsPerPage);
        // Generate array of page numbers
        this.pages = Array.from({ length: this.totalPages }, (_, i) => i + 1);
      },
      error: (err) => {
        if (err.error.msg == 'No record found.') {
          this.allNews = []
        }
      },
    })
  }
  // getNewsByTypeAndSubSection(type: any, categoryName?: any) {
  //   this._homeService.getNewsByTypeAndCategory(categoryName).subscribe({
  //     next: (data) => {
  //       console.log('news data===>', data)
  //       this.allNews = data
  //     },
  //     error: (err) => {
  //       if (err.error.msg == 'No record found.') {
  //         this.allNews = []
  //       }
  //     },
  //   })
  // }

  // gotoNewsDetail(newsId: any) {
  //   this.router.navigate(['news-detail', { _id: newsId }])
  // }

  gotoNewsDetail(newsId: any, newsTitle: string) {
    this.router.navigate(['news-detail', this.formatTitleForUrl(newsTitle), newsId])
  }

  formatTitleForUrl(title: string): string {
    // Remove spaces and convert to lowercase, you can add more logic based on your requirements
    return title.replace(/\s+/g, '-').toLowerCase()
  }

  gotoProfile(id:any) {
    this.router.navigate(['view-profile',id])
  }

  onFormSubmit(){
    if(this.newsLetterForm.valid){
      let obj={
        email: this.newsLetterForm.controls.email.value,
      }
      this._homeService.addNewsLetter(obj).subscribe({
        next:(res:any)=>{
          if(res.success == true){
            Swal.fire({
              title:'Sign-up Successfully',
              icon: 'success'
            })
            // this.newsLetterForm.controls.email.value = ' ';
            this.newsLetterForm.reset();
          }
        },
        error:(err:any)=>{
          Swal.fire({
            title:'Email already Registered',
            icon:'error'
          })
        }
      });
  }
  }

  setPage(page: number): void {
    // Check if page is within valid range
    if (page >= 1 && page <= this.totalPages) {
      this.currentPage = page;
      if (this.categoryName) {
          this.getNews(this.currentPage, this.itemsPerPage,this.categoryName)
        } else if (this.subCategory) {
          this.getNews(this.currentPage, this.itemsPerPage,'', this.subCategory)
        }
    }
  }

  prevPage(): void {
    if (this.currentPage > 1) {
      this.currentPage--;
      if (this.categoryName) {
        this.getNews(this.currentPage, this.itemsPerPage,this.categoryName)
      } else if (this.subCategory) {
        this.getNews(this.currentPage, this.itemsPerPage,'', this.subCategory)
      }
    }
  }

  nextPage(): void {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
      if (this.categoryName) {
        this.getNews(this.currentPage, this.itemsPerPage,this.categoryName)
      } else if (this.subCategory) {
        this.getNews(this.currentPage, this.itemsPerPage,'', this.subCategory)
      }
    }
  }
}
