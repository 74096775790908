<div class="footer">
  <div class="container">
    <div class="row">
      <div class="col-sm-4">
        <img src="assets/image/footer-logo.png" alt="logo" width="100%" />
        <div class="about my-3">
          About Us
          <hr />
        </div>
        <p class="content common">
          We offer unbiased, factual insights for a comprehensive understanding of Regional, International and Socio-political matters
        </p>
        <!-- <div class="phone common">+91-9865456589</div> -->
        <div class="email common">contact&#64;southasiandigest.com</div>
        <div class="social my-3">
          <ul>
            <li>
              <a href="https://www.facebook.com/SouthAsianDigest/" target="_blank"
                ><img src="assets/image/Icon-facebook.png" width="13px" alt="faceboot"
              /></a>
            </li>
            <li>
              <a href="https://www.instagram.com/southasiandigest/"
                ><img src="assets/image/Icon-instagram.png" width="20px" alt="instagram"
              /></a>
            </li>
            <li>
              <a href="https://twitter.com/SADigestOnline"
                ><img src="assets/image/Icon-twitter.png" width="23px" alt="teitter"
              /></a>
            </li>
            <li>
              <a href="https://web.whatsapp.com/"
                ><img src="assets/image/Icon-whatsapp.png" width="23px" alt="whatsapp"
              /></a>
            </li>
            <li>
              <a href="https://www.youtube.com/@SouthAsianDigest/featured"
                ><img src="assets/image/Icon-youtube.png" width="26px" alt="youtube"
              /></a>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-sm-4">
        <div class="company common mx-4 mt-3">Company</div>
        <hr class="companyBottonHr" />
        <div class="page">
          <ul>
            <li (click)="gotoAboutUs()">About Us</li>
            <li>Our Team</li>
            <li [routerLink]="'privacy-policy'">Privacy Policy</li>
          </ul>
        </div>
      </div>
      <div class="col-sm-4">
        <div class="company common mt-3">Trending Post</div>
        <hr class="trendingyBottonHr" />
        <!-- <div class="row trending">
        </div> -->
        <div class="d-flex trending my-2" *ngFor="let trending of trendingNews; let i = index">
          <div class="flex-shrink-0 footerImage" *ngIf="i >= 0 && i < 3">
            <img
              src="{{ trending?.images[0]?.imageUrl ? trending?.images[0]?.imageUrl : 'assets/image/no-image.png' }}"
              alt="..."
              width="100%"
              height="100%"
            />
          </div>
          <div class="flex-grow-1 ms-3" *ngIf="i >= 0 && i < 3">
            <p class="trendingText topic" (click)="gotoNewsDetail(trending?._id, trending?.title)">
              {{ trending.title | slice: 0 : 60 }}{{ trending.title.length > 60 ? '...' : '' }}
            </p>
            <p class="trendingDate">{{ trending?.createdAt | date: 'MMMM d, h:mma' }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
