<div class="container">
  <h5 class="fw-bold">
    <!-- {{ type ? type + ' / ' : '' }} -->
    {{ categoryName ? categoryName + ' / ' : '' }}
    {{ subCategory ? subCategory : ' ' }}
  </h5>
  <hr />
  <div class="row my-4">
    <div class="col-sm-9 border-end">
      <div *ngFor="let news of allNews">
        <div class="d-flex align-items-center my-3 topic">
          <div class="flex-shrink-0 view-img">
            <img
              src="{{ news?.images[0]?.imageUrl ? news?.images[0]?.imageUrl : 'assets/image/no-image.png' }}"
              alt="..."
              width="100%"
            />
          </div>
          <div class="flex-grow-1 ms-3" >
            <a [routerLink]="['/news-detail',formatTitleForUrl(news?.title),news?._id]">
              <h5 class="fw-bold mob-head">{{ (news?.title | slice: 0 : 55) + '...' }}</h5>
            <p
              class="card-text"
              [innerHTML]="(news?.content | slice: 0 : 100) + (news?.content.length > 100 ? '...' : '')"
            ></p>
            </a>
            <p class="time" >
              <i class="fa fa-clock-o" aria-hidden="true"> </i>&nbsp;
              {{ news?.createdAt | date: 'MMMM d,h:mma' }} &nbsp;By <a [routerLink]="['/view-profile',news.createdBy._id]">{{ news?.createdBy?.firstName+ ' '+ news?.createdBy?.lastName }}</a>
            </p>
          </div>
        </div>
        <hr />
      </div>
      <div *ngIf="!allNews || allNews.length === 0">
        <img src="assets/image/no-news.avif" alt="No News" width="35%" class="center" />
        <h4 class="text-center my-3">No news available</h4>
        <!-- You can also display a default image here -->
      </div>
      <nav aria-label="Page navigation" *ngIf="this.totalPages">
        <ul class="pagination justify-content-center mt-4">
          <li class="page-item" [class.disabled]="currentPage === 1">
            <a class="page-link" (click)="prevPage()" tabindex="-1" [attr.aria-disabled]="currentPage === 1 ? true : null">Previous</a>
          </li>
          <li class="page-item" *ngFor="let page of pages" [class.active]="page === currentPage">
            <a class="page-link" (click)="setPage(page)">{{ page }}</a>
          </li>
          <li class="page-item" [class.disabled]="currentPage === totalPages">
            <a class="page-link" (click)="nextPage()" [attr.aria-disabled]="currentPage === totalPages ? true : null">Next</a>
          </li>
        </ul>
      </nav>
    </div>
    <div class="col-sm-3">
      <div class="border my-3 shadow" *ngIf="!userName">
        <div class="email-img p-3">
          <img src="assets/image/logo.png" alt="" width="100%" />
        </div>
        <h3 class="text-center fw-bold my-4">South Asian Newsletter</h3>
        <form [formGroup]="newsLetterForm" (ngSubmit)="onFormSubmit()">
          <div class="mx-3">
            <input type="email" placeholder="Enter Your Email" class="form-control" formControlName="email" />
            <mat-error *ngIf="newsLetterForm.get('email')?.touched && newsLetterForm.get('email')?.errors?.['required']"
              >Email required*</mat-error>
              <mat-error *ngIf="newsLetterForm.get('email')?.touched && newsLetterForm.get('email')?.errors?.email">
                Invalid Email 
              </mat-error>
            <button class="btn btn-warning my-2 w-100">Sign Up</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
