<div class="news-flash-main d-flex justify-content-center" ngSkipHydration>
  <div class="d-flex f-slide">
    <div class="news-flash fw-bold"><i class="fa fa-bolt" aria-hidden="true"></i> Newsflash:</div>
    <div id="carouselExampleControls" class="carousel slide w-75" data-bs-ride="carousel" data-bs-interval="3000">
      <div class="carousel-inner" *ngIf="flashNews.length>0">
        <div *ngFor="let flash of flashNews; let i=index">
          <div class="carousel-item" [ngClass]="{ active: i === 0 }">
            <a [routerLink]="['/news-detail', formatTitleForUrl(flash?.title), flash?._id]">
              <div class="mx-2 slide-news topic">
                {{ (flash?.title | slice: 0 : 100) + '...' }}
              </div>
            </a>
          </div>
        </div>
      </div>
      <div>
        <button class="carousel-control-prev btn-left" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
          <i class="fa fa-angle-left" aria-hidden="true"></i>
          <span class="visually-hidden">Previous</span>
        </button>
        <button class="carousel-control-next btn-ryt" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
          <i class="fa fa-angle-right" aria-hidden="true"></i>
          <span class="visually-hidden">Next</span>
        </button>
      </div>
    </div>
  </div>
</div>


<div class="container my-4" *ngIf="newsList" ngSkipHydration>
  <div class="row">
    <div class="col-lg-3 col-md-12 col-sm-12 border-end">
      <div class="left-heading" *ngIf="newsList?.home?.left?.length > 0">
        <div *ngFor="let left of newsList?.home?.left">
          <div class="d-flex">
           <a [routerLink]="['/news-detail', formatTitleForUrl(left?.title), left?._id]">
            <div class="flex-grow-1 fw-bold text-capitalize topic">
              {{ (left?.title | slice: 0 : 50) + '...' }}
              <a [routerLink]="['/view-profile', left?.createdBy?._id]"><p class="author m-0 topic">{{ left?.createdBy?.firstName + ' ' + left?.createdBy?.lastName}}</p></a>
            </div>
           </a>
            <a [routerLink]="['/news-detail', formatTitleForUrl(left?.title), left?._id]">
              <div class="flex-shrink-0">
                <div class="middle-image left-img">
                  <img
                    src="{{ left?.images[0]?.imageUrl ? left?.images[0]?.imageUrl : 'assets/image/no-image.png' }}"
                    class="card-img-top"
                    alt="..."
                    width="100%"
                    height="100%"
                  />
                </div>
              </div>
            </a>
          </div>
          <hr />
        </div>
        <!-- <div class="card border-0" *ngIf="newsList?.main?.left[4]">
          <img
            src="{{
              newsList?.main?.left[4]?.images[0]?.imageUrl
                ? newsList?.main?.left[4]?.images[0]?.imageUrl
                : 'assets/image/no-image.png'
            }} "
            class="card-img-top mb-2"
            alt="..."
          />
          <div class="">
            <h5 class="card-title fw-bold topic" (click)="gotoNewsDetail(newsList?.main?.left[0]?._id)">
              {{ (newsList?.main?.left[4]?.title | slice: 0 : 50) + '...' }}
            </h5>
            <p class="card-text">
              <small class="text-muted">
                <i class="fa fa-clock-o" aria-hidden="true"> </i>
                {{ newsList?.main?.left[4]?.createdAt | date: 'MMMM d, h:mma' }} By
                {{ newsList?.main?.left[4]?.createdBy?.firstName }}</small
              >
            </p>
          </div>
        </div> -->
      </div>
    </div>
    <div class="col-lg-6 col-md-12 col-sm-12 border-end" *ngIf="newsList?.home?.center?.length > 0">
      <a  [routerLink]="['/news-detail', formatTitleForUrl(newsList?.home?.center[0]?.title), newsList?.home?.center[0]?._id]">
        <div
        class="main-image"
      >
        <img
          src="{{
            newsList?.home?.center[0]?.images[0]?.imageUrl
              ? newsList?.home?.center[0]?.images[0]?.imageUrl
              : 'assets/image/no-image.png'
          }}"
          alt="main-image"
          class="img-fluid"
          height="100%"
          width="100%"
        />
      </div>
      </a>
      <div class="main-upper-content d-flex">
        <button type="button" class="breaking-news-button rounded-pill mx-5 my-2">Breaking News</button>
        <p class="date-time my-2">
          <i class="fa fa-clock-o" aria-hidden="true"></i>&nbsp;{{
            newsList?.home?.center[0]?.createdAt | date: 'MMMM d, h:mma'
          }}
        </p>
        <a [routerLink]="['/view-profile', newsList?.home?.center[0]?.createdBy?._id]">
          <p class="by my-2 mx-5 topic">
            By<span class="author mx-1"
              >{{ newsList?.home?.center[0]?.createdBy?.firstName }} &nbsp;{{
                newsList?.home?.center[0]?.createdBy?.lastName
              }}</span
            >
          </p>
        </a>
      </div>
      <div>
        <a [routerLink]="['/news-detail', formatTitleForUrl(newsList?.home?.center[0]?.title), newsList?.home?.center[0]?._id]">
          <p
          class="main-heading topic my-3"
        >
          {{ newsList?.home?.center[0]?.title }}
        </p>
        </a>
      </div>
      <hr />
      <div class="mb-3" *ngIf="newsList?.home?.center[1]">
        <div class="d-flex">
          <div class="flex-grow-1 ms-3">
            <a [routerLink]="['/news-detail', formatTitleForUrl(newsList?.home?.center[1]?.title), newsList?.home?.center[1]?._id]">
              <p
              class="card-title fw-bold text-capitalize topic"
            >
              {{ (newsList?.home?.center[1]?.title | slice: 0 : 90) + '...' }}
            </p>
            </a>
            <a [routerLink]="['/view-profile', newsList?.home?.center[1]?.createdBy?._id]">
              <p class="my-2 opacity-50 topic">
                <i class="fa fa-clock-o" aria-hidden="true"> </i>&nbsp;{{
                  newsList?.home?.center[1]?.createdAt | date: 'MMMM d, h:mma'
                }}
                &nbsp; By {{ newsList?.home?.center[1]?.createdBy?.firstName + ' ' + newsList?.home?.center[1]?.createdBy?.lastName}}
              </p>
            </a>
          </div>
          <div class="flex-shrink-0">
            <a [routerLink]="['/news-detail', formatTitleForUrl(newsList?.home?.center[1]?.title),newsList?.home?.center[1]?._id,]">
              <div
              class="bottom-img"
            >
              <img
                src="{{
                  newsList?.home?.center[1]?.images[0]?.imageUrl
                    ? newsList?.home?.center[1]?.images[0]?.imageUrl
                    : 'assets/image/no-image.png'
                }}"
                class="img-fluid rounded-start main-sec-img"
                alt="..."
              />
            </div>
            </a>
          </div>
        </div>
      </div>
      <hr />
      <div class="mb-3" *ngIf="newsList?.home?.center[2]">
        <div class="d-flex">
          <div class="flex-grow-1 ms-3">
            <a [routerLink]="['/news-detail',formatTitleForUrl( newsList?.home?.center[2]?.title) ,newsList?.home?.center[2]?._id]">
              <p
              class="card-title fw-bold text-capitalize topic"
            >
              {{ newsList?.home?.center[2]?.title }}
            </p>
            </a>
            <a [routerLink]="['/view-profile', newsList?.home?.center[2]?.createdBy?._id]">
              <p class="my-2 opacity-50 topic">
                <i class="fa fa-clock-o" aria-hidden="true"> </i>&nbsp;{{
                  newsList?.home?.center[2]?.createdAt | date: 'MMMM d, h:mma'
                }}
                &nbsp; By {{ newsList?.home?.center[2]?.createdBy?.firstName + ' ' + newsList?.home?.center[2]?.createdBy?.lastName }}
              </p>
            </a>

          </div>
          <div class="flex-shrink-0">
           <a [routerLink]="['/news-detail',formatTitleForUrl(newsList?.home?.center[2]?.title) ,newsList?.home?.center[2]?._id ]">
            <div
            class="bottom-img"
          >
            <img
              src="{{
                newsList?.home?.center[2]?.images[0]?.imageUrl
                  ? newsList?.home?.center[2]?.images[0]?.imageUrl
                  : 'assets/image/no-image.png'
              }}"
              class="img-fluid rounded-start main-sec-img"
              alt="..."
            />
          </div>
           </a>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-3 col-md-12 col-sm-12 border-left border-start mobile_screen_div" *ngIf="this.newsList?.home?.right.length > 0">
      <div class="right-heading border-bottom" *ngFor="let right of newsList?.home?.right">
       <a [routerLink]="['/news-detail',formatTitleForUrl(right?.title) ,right?._id]">
        <p class="heading topic right-head" >
          {{ right.title | slice: 0 : 100 }}{{ right.title.length > 100 ? '...' : '' }}
        </p>
       </a>
        <p class="time">
          <i class="fa fa-clock-o" aria-hidden="true"> </i>&nbsp;{{ right?.createdAt | date: 'MMMM d, h:mma' }}
        </p>
      </div>
    </div>
  </div>
  <p class="h-line border-bottom my-5"></p>
  <h2>INDIA</h2>
  <hr />
  <div class="row mt-4">
    <div class="col-sm-12 col-lg-9 col-md-12">
      <div class="">
        <div class="row g-0" *ngIf="newsList?.india?.length > 0">
          <div class="col-md-5 pr-5">
            <div class="card-body">
              <p>{{ newsList?.india[0]?.categoryName }}</p>
              <a [routerLink]="['/news-detail',formatTitleForUrl(newsList?.india[0]?.title), newsList?.india[0]?._id ]">
                <h5
                class="card-title fw-bold text-capitalize topic pb-2 h3"
              >
                {{ newsList?.india[0]?.title }}
              </h5>
              </a>
             <a [routerLink]="['/view-profile', newsList?.india[0]?.createdBy?._id]">
              <p class="card-text topic">
                <small class="text-muted"
                  ><i class="fa fa-clock-o" aria-hidden="true"></i>
                  {{ newsList?.india[0]?.createdAt | date: 'MMMM d, h:mma' }} &nbsp; By
                  <span>{{ newsList?.india[0]?.createdBy.firstName }}</span> &nbsp;
                  <span>{{ newsList?.india[0]?.createdBy.lastName }}</span>
                </small>
              </p>
             </a>
              <a [routerLink]="['/news-detail',formatTitleForUrl(newsList?.india[0]?.title), newsList?.india[0]?._id]">
                <span
                class="card-text"
                [innerHTML]="
                  (newsList?.india[0]?.content | slice: 0 : 160) +
                  (newsList?.india[0]?.content.length > 160 ? '...' : '')
                "
              >
                <!-- {{newsList.news.INDIA.TECHNOLOGY[0].content}} -->
              </span>
              </a>
            </div>
          </div>
          <div class="col-md-7">
            <a [routerLink]="['/news-detail',formatTitleForUrl(newsList?.india[0]?.title), newsList?.india[0]?._id ]">
              <div class="second-main-img" >
                <img
                  src="{{
                    newsList?.india[0]?.images[0]?.imageUrl
                      ? newsList?.india[0]?.images[0]?.imageUrl
                      : 'assets/image/no-image.png'
                  }}"
                  class="img-fluid rounded-start"
                  alt="..."
                />
              </div>
            </a>
          </div>
        </div>
      </div>
      <hr />
      <div class="row my-4" *ngIf="newsList?.india?.length > 0">
        <ng-container *ngFor="let upper of this.newsList?.india; let i = index">
          <ng-container *ngIf="i !== 0 && i <= 6">
            <div class="col-sm-4 border-end">
              <div class="">
                <div class="card-body">
                <a [routerLink]="['/news-detail',formatTitleForUrl(upper.title), upper._id ]">
                  <ng-container *ngIf="i > 3" >
                    <hr />
                    <ng-container *ngIf="this.newsList?.india[i].images.length; else noImage">
                      <img src="{{ this.newsList?.india[i].images[0].imageUrl }}" width="100%" alt="news_image" />
                    </ng-container>
                    <ng-template #noImage>
                      <img src="/assets/image/no-image.png" alt="news_image" width="100%" />
                    </ng-template>
                  </ng-container>
                </a>
                  <a [routerLink]="['/news-detail',formatTitleForUrl(upper?.title), upper?._id]">
                    <h5
                    class="card-title fw-bold text-capitalize topic my-2"                    
                  >
                    {{ (upper?.title | slice: 0 : 50) + '...' }}
                  </h5>
                  </a>
                  <p
                    *ngIf="i <= 3"
                    class="card-text"
                    [innerHTML]="(upper?.content | slice: 0 : 50) + (upper?.content.length > 50 ? '...' : '')"
                  ></p>
                  <a [routerLink]="['/view-profile', upper?.createdBy?._id]">
                    <p class="card-text topic">
                      <small class="text-muted"
                        ><i class="fa fa-clock-o" aria-hidden="true"></i>
                        {{ upper?.createdAt | date: 'MMMM d, h:mma' }} By {{ upper?.createdBy?.firstName + ' ' + upper?.createdBy?.lastName }}</small
                      >
                    </p>
                  </a>
                </div>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>
    <div class="col-sm-12 col-md-6 col-lg-3">
      <div class="social-links d-flex justify-content-between mb-3">
        <!-- <div class=""> -->
        <div class="facebook border">
          <a href="https://www.facebook.com/SouthAsianDigest/" target="_blank">
            <img src="/assets/image/facebook-icon.png" alt="" width="100%" height="100%" />
          </a>
        </div>
        <div class="twitter border">
          <a href="https://twitter.com/SADigestOnline" target="_blank">
            <img src="/assets/image/twitter-icon.png" alt="" width="100%" height="100%" />
          </a>
        </div>
        <div class="youtube border">
          <a href="https://www.youtube.com/@SouthAsianDigest/featured" target="_blank">
            <img src="/assets/image/youtube-icon.png" alt="" width="100%" height="100%" />
          </a>
        </div>
        <div class="insta border">
          <a href="https://www.instagram.com/southasiandigest/" target="_blank">
            <img src="/assets/image/insta_icon.jpeg" alt="" width="100%" height="100%" />
          </a>
        </div>
        <!-- </div> -->
      </div>
      <div class="border other-item">
        <ul class="nav nav-fill nav-tabs" role="tablist">
          <li class="nav-item1" role="presentation">
            <a
              class="nav-link active"
              id="fill-tab-3"
              data-bs-toggle="tab"
              href="#fill-tabpanel-3"
              role="tab"
              aria-controls="fill-tabpanel-3"
              aria-selected="true"
              >Reports</a
            >
          </li>
          <li class="nav-item1" role="presentation">
            <a
              class="nav-link"
              id="fill-tab-4"
              data-bs-toggle="tab"
              href="#fill-tabpanel-4"
              role="tab"
              aria-controls="fill-tabpanel-4"
              aria-selected="false"
              >Seminars</a
            >
          </li>
          <li class="nav-item1" role="presentation">
            <a
              class="nav-link"
              id="fill-tab-5"
              data-bs-toggle="tab"
              href="#fill-tabpanel-5"
              role="tab"
              aria-controls="fill-tabpanel-5"
              aria-selected="false"
              >E-Magazine</a
            >
          </li>
        </ul>
        <div class="tab-content" id="tab-content-another">
          <div
            class="tab-pane active"
            id="fill-tabpanel-3"
            role="tabpanel"
            aria-labelledby="fill-tab-3"
            *ngIf="newsList?.other?.reports"
          >
            <div class="d-flex my-3" *ngFor="let report of newsList?.other?.reports; let i = index">
             <a [routerLink]="['/news-detail',formatTitleForUrl(report?.title), report?._id]">
              <div
              class="flex-shrink-0 bottom-slider right-menu"
              *ngIf="i >= 0 && i < 4"
            >
              <img
                src="{{ report?.images[0]?.imageUrl ? report?.images[0]?.imageUrl : 'assets/image/no-image.png' }}"
                class="img-fluid mx-1"
                alt="..."
              />
            </div>
             </a>
             <a [routerLink]="['/news-detail',formatTitleForUrl(report?.title), report?._id]">
              <div
              class="flex-grow-1 ms-3 side-heading topic pr-2"
              *ngIf="i >= 0 && i < 4"
            >
              <p class="m-0 opacity-50 mb-3">{{ report.createdAt | date: 'MMMM d' }}</p>
              <p class="m-0 fw-bold">{{ report.title | slice: 0 : 35 }}{{ report.title.length > 50 ? '...' : '' }}</p>
            </div>
             </a>
            </div>
            <a [routerLink]="['/view-more', { categoryName: '', subCategory: 'Reports' }]">
              <div class="border-top mx-3 text-center show-more py-1 m-1 w-50 mx-auto" >
                Read More News
              </div>
            </a>
          </div>
          <div
            class="tab-pane"
            id="fill-tabpanel-4"
            role="tabpanel"
            aria-labelledby="fill-tab-4"
            *ngIf="newsList?.other?.seminars"
          >
            <div class="d-flex my-3" *ngFor="let seminars of newsList?.other?.seminars; let i = index">
             <a [routerLink]="['/news-detail',formatTitleForUrl(seminars?.title), seminars?._id ]">
              <div
              class="flex-shrink-0 bottom-slider right-menu"
              *ngIf="i >= 0 && i < 4"
            >
              <img
                src="{{
                  seminars?.images[0]?.imageUrl ? seminars?.images[0]?.imageUrl : 'assets/image/no-image.png'
                }}"
                class="img-fluid mx-1"
                alt="..."
              />
            </div>
             </a>
              <a [routerLink]="['/news-detail',formatTitleForUrl(seminars?.title), seminars?._id]">
                <div
                class="flex-grow-1 ms-3 side-heading topic pr-2"
                *ngIf="i >= 0 && i < 4"
              >
                <p class="m-0 opacity-50 mb-3">{{ seminars.createdAt | date: 'MMMM d' }}</p>
                <p class="m-0 fw-bold">
                  {{ seminars.title | slice: 0 : 35 }}{{ seminars.title.length > 50 ? '...' : '' }}
                </p>
              </div>
              </a>
            </div>
            <a [routerLink]="['/view-more',{ categoryName: '', subCategory: 'Seminars' }]">
              <div class="border-top mx-3 text-center show-more py-1 m-1 w-50 mx-auto" >
                Read More News
              </div>
            </a>
          </div>
          <div
            class="tab-pane"
            id="fill-tabpanel-5"
            role="tabpanel"
            aria-labelledby="fill-tab-5"
            *ngIf="newsList?.other?.eMagazines"
          >
            <div class="d-flex my-3" *ngFor="let magazine of newsList?.other?.eMagazines; let i = index">
              <a [routerLink]="['/news-detail',formatTitleForUrl(magazine?.title), magazine?._id ]">
                <div
                class="flex-shrink-0 bottom-slider right-menu"
                *ngIf="i >= 0 && i < 4"
              >
                <img
                  src="{{
                    magazine?.images[0]?.imageUrl ? magazine?.images[0]?.imageUrl : 'assets/image/no-image.png'
                  }}"
                  class="img-fluid mx-1"
                  alt="..."
                />
              </div>
              </a>
              <a [routerLink]="['/news-detail',formatTitleForUrl(magazine?.title), magazine?._id]">
                <div
                class="flex-grow-1 ms-3 side-heading topic pr-2"
                *ngIf="i >= 0 && i < 4"
              >
                <p class="m-0 opacity-50 mb-3">{{ magazine.createdAt | date: 'MMMM d' }}</p>
                <p class="m-0 fw-bold">
                  {{ magazine.title | slice: 0 : 35 }}{{ magazine.title.length > 50 ? '...' : '' }}
                </p>
              </div>
              </a>
            </div>
            <a [routerLink]="['view-more',{ categoryName: '', subCategory: 'Reports' }]">
              <div class="border-top mx-3 text-center show-more py-1 m-1 w-50 mx-auto">
                Read More News
              </div>
            </a>
          </div>
        </div>
      </div>
      <div class="border my-3" *ngIf="!userName">
        <div class="email-img p-3">
          <img src="assets/image/logo.png" alt="" width="100%">
        </div>
        <h3 class="text-center fw-bold my-4">South Asian Newsletter</h3>
        <form  [formGroup]="newsLetterForm" (ngSubmit)="onFormSubmit()">
          <div class="mx-3">
            <input type="email" placeholder="Enter Your Email" class="form-control" formControlName="email" />
            <mat-error *ngIf="newsLetterForm.get('email')?.touched && newsLetterForm.get('email')?.errors?.['required']"
              >Email required*</mat-error>
              <mat-error *ngIf="newsLetterForm.get('email')?.touched && newsLetterForm.get('email')?.errors?.email">
                Invalid Email 
              </mat-error>
            <button class="btn btn-warning my-2 w-100">Sign Up</button>
          </div>
        </form>

      </div>
    </div>
  </div>
  <a [routerLink]="['view-more',{ categoryName: 'INDIA', subCategory: '' }]">
 <button type="button" class="btn btn-outline-warning my-2">View More</button>
  </a>
  <p class="h-line border-bottom my-4"></p>
  <h2>WORLD</h2>
  <hr />
  <div class="world" *ngIf="newsList?.world?.length > 0">
    <div class="world-main">
      <div class="card-body p-5">
        <p>{{ newsList?.world[0]?.categoryName }}</p>
        <a [routerLink]="['/news-detail',formatTitleForUrl(newsList?.world[0]?.title), newsList?.world[0]?._id]">
          <h3
          class="card-title fw-bold topic"
        >
          {{ (newsList?.world[0]?.title | slice: 0 : 70) + '...' }}
        </h3>
        </a>
        <p
          class="card-text my-3"
          [innerHTML]="
            (newsList?.world[0]?.content | slice: 0 : 150) + (newsList?.world[0]?.content.length > 150 ? '...' : '')
          "
        ></p>
        <p class="card-text topic">
          <a  [routerLink]="['/view-profile', newsList?.world?.[0]?.createdBy?._id]">
            <small class="text-muted" style="color: white !important"
              >By {{ newsList?.world[0]?.createdBy?.firstName + ' '+  newsList?.world[0]?.createdBy?.lastName }} &nbsp;<i class="fa fa-clock-o" aria-hidden="true"></i>
              {{ newsList?.world[0]?.createdAt | date: 'MMMM d, h:mma' }}</small
            >
          </a>
        </p>
      </div>
    </div>
    <div class="world-img">
      <a [routerLink]="['/news-detail',formatTitleForUrl(newsList?.world[0]?.title), newsList?.world[0]?._id]">
        <div class="right-image">
          <img
            src="{{
              newsList?.world[0]?.images[0]?.imageUrl
                ? newsList?.world[0]?.images[0]?.imageUrl
                : 'assets/image/no-image.png'
            }}"
            alt=""
          />
        </div>
      </a>
    </div>
  </div>
  <div class="row d-flex my-3" *ngIf="newsList?.world.length>0">
    <div class="col-sm-12 col-md-6 col-lg-3 pt-3 border-end" *ngFor="let worldData of newsList?.world; let i = index">
      <div class="" *ngIf="i >= 0 && i < 4">
        <div class="card-body">
          <a [routerLink]="['/news-detail',formatTitleForUrl(worldData?.title), worldData?._id]">
            <h5 class="card-title fw-bold topic">
              {{ (worldData?.title | slice: 0 : 70) + '...' }}
            </h5>
          </a>
          <p
            class="card-text my-3"
            [innerHTML]="(worldData?.content | slice: 0 : 155) + (worldData?.content.length > 150 ? '...' : '')"
          ></p>
          <a [routerLink]="['/view-profile',worldData?.createdBy?._id]">
            <p class="card-text topic" >
              <small class="text-muted">
                By {{ worldData?.createdBy?.firstName + ' ' + worldData?.createdBy?.lastName }}
                <!-- &nbsp; <i class="fa fa-clock-o" aria-hidden="true"></i>
                {{ worldData?.createdAt | date: 'MMMM d, h:mma' }} -->
              </small>
            </p>
          </a>
        </div>
      </div>
    </div>
  </div>
  <a [routerLink]="['view-more',{ categoryName: 'WORLD', subCategory: '' }]"><button type="button" class="btn btn-outline-warning my-2">View More</button></a>
  <p class="h-line border-bottom my-5"></p>
  <h2>OPINIONS & ANALYSIS</h2>
  <hr />
  <div class="row">
    <div class="col-sm-12 col-md-6 col-lg-3 border-end" *ngIf="newsList?.opinionAndAnalysis?.factCheck?.length > 0">
      <h4>FACT CHECK</h4>
      <div class="underline"></div>
      <a [routerLink]="['/news-detail',formatTitleForUrl(newsList?.opinionAndAnalysis?.factCheck[0]?.title), newsList?.opinionAndAnalysis?.factCheck[0]?._id]">
        <div>
        <div class="opt-main">
          <img
            src="{{
              newsList?.opinionAndAnalysis?.factCheck[0]?.images[0]?.imageUrl
                ? newsList?.opinionAndAnalysis?.factCheck[0]?.images[0]?.imageUrl
                : '/assets/image/no-image.png'
            }}"
            class="card-img-top"
            alt="..."
          />
        </div>
        <div class="card-body">
          <a [routerLink]="['/news-detail',formatTitleForUrl(newsList?.opinionAndAnalysis?.factCheck[0]?.title), newsList?.opinionAndAnalysis?.factCheck[0]?._id]">
            <h5
            class="card-title fw-bold topic my-3"
          >
            {{ (newsList?.opinionAndAnalysis?.factCheck[0]?.title | slice: 0 : 60) + '...' }}
          </h5>
          </a>
          <a [routerLink]="['/view-profile',newsList?.opinionAndAnalysis?.factCheck[0]?.createdBy?._id]">
            <p class="card-text">
              <small class="text-muted topic">
                <i class="fa fa-clock-o" aria-hidden="true"></i>
                {{ newsList?.opinionAndAnalysis?.factCheck[0]?.createdAt | date: 'MMMM d, h:mma' }} &nbsp; By
                {{ newsList?.opinionAndAnalysis?.factCheck[0]?.createdBy?.firstName + ' ' + newsList?.opinionAndAnalysis?.factCheck[0]?.createdBy?.lastName }}</small
              >
            </p>
          </a>
        </div>
      </div>
      </a>
      <!-- <hr /> -->
      <div *ngFor="let fact of newsList?.opinionAndAnalysis?.factCheck; let i = index">
        <div class="d-flex align-items-center">
          <a [routerLink]="['/news-detail',formatTitleForUrl(fact.title), fact._id]">
            <div class="flex-grow-1 fw-bold topic" *ngIf="i > 0 && i < 4" >
              {{ (fact.title | slice: 0 : 60) + '...' }}
            </div>
          </a>
          <div class="flex-shrink-0" *ngIf="i > 0 && i < 4">
            <a [routerLink]="['/news-detail',formatTitleForUrl(fact.title), fact._id]">
              <div class="middle-image opt-img">
                <img
                  src="{{ fact.images[0]?.imageUrl ? fact.images[0]?.imageUrl : '/assets/image/no-image.png' }}"
                  class="card-img-top"
                  width="100%"
                  height="100%"
                  alt="..."
                />
              </div>
            </a>
          </div>
        </div>
        <hr />
      </div>
      <a [routerLink]="['/view-more',{categoryName:'',subCategory:'FACT CHECK'}]">
        <p class="goto-news topic">
          Go to Fact Check
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="13"
            height="13"
            fill="currentColor"
            class="bi bi-caret-right-fill"
            viewBox="0 0 16 16"
          >
            <path
              d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"
            />
          </svg>
        </p>
      </a>
    </div>
    <div class="col-sm-12 col-md-6 col-lg-3 border-end" *ngIf="newsList?.opinionAndAnalysis?.columns?.length > 0">
      <h4>COLUMNS</h4>
      <div class="underline"></div>
     <a [routerLink]="['/news-detail',formatTitleForUrl(newsList?.opinionAndAnalysis?.columns[0]?.title), newsList?.opinionAndAnalysis?.columns[0]?._id]">
      <div>
      <div class="opt-main">
        <img
          src="{{
            newsList?.opinionAndAnalysis?.columns[0]?.images[0]?.imageUrl
              ? newsList?.opinionAndAnalysis?.columns[0]?.images[0]?.imageUrl
              : '/assets/image/no-image.png'
          }}"
          class="card-img-top"
          alt="..."
        />
      </div>
      <div class="card-body">
        <a [routerLink]="['/news-detail',formatTitleForUrl(newsList?.opinionAndAnalysis?.columns[0]?.title), newsList?.opinionAndAnalysis?.columns[0]?._id]">
          <h5 class="card-title fw-bold topic my-3">
          {{ (newsList?.opinionAndAnalysis?.columns[0]?.title | slice: 0 : 60) + '...' }}
        </h5>
        </a>
       <a [routerLink]="['/view-profile',newsList?.opinionAndAnalysis?.columns[0]?.createdBy?._id]">
        <p class="card-text">
          <small class="text-muted topic">
            <i class="fa fa-clock-o" aria-hidden="true"></i>
            {{ newsList?.opinionAndAnalysis?.columns[0]?.createdAt | date: 'MMMM d, h:mma' }} &nbsp; By
            {{ newsList?.opinionAndAnalysis?.columns[0]?.createdBy?.firstName + ' ' + newsList?.opinionAndAnalysis?.columns[0]?.createdBy?.lastName}}</small
          >
        </p>
       </a>
      </div>
    </div>
     </a>
      <!-- <hr /> -->
      <div *ngFor="let columns of newsList?.opinionAndAnalysis?.columns; let i = index">
        <div class="d-flex align-items-center">
         <a [routerLink]="['/news-detail',formatTitleForUrl(columns.title), columns._id]">
          <div
          class="flex-grow-1 fw-bold topic"
          *ngIf="i > 0 && i < 4"
        >
          {{ (columns?.title | slice: 0 : 60) + '...' }}
        </div>
         </a>
          <div class="flex-shrink-0" *ngIf="i > 0 && i < 4">
            <a [routerLink]="['/news-detail',formatTitleForUrl(columns.title), columns._id]">
              <div class="middle-image opt-img">
                <img
                  src="{{ columns.images[0]?.imageUrl ? columns.images[0]?.imageUrl : '/assets/image/no-image.png' }}"
                  class="card-img-top"
                  width="100%"
                  height="100%"
                  alt="..."
                />
              </div>
            </a>
          </div>
        </div>
        <hr />
      </div>
      <a [routerLink]="['/view-more',{categoryName:'',subCategory:'COLUMNS'}]">
        <p class="goto-news topic">
          Go to Columns
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="13"
            height="13"
            fill="currentColor"
            class="bi bi-caret-right-fill"
            viewBox="0 0 16 16"
          >
            <path
              d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"
            />
          </svg>
        </p>
      </a>
    </div>
    <div class="col-sm-12 col-md-6 col-lg-3 border-end" *ngIf="newsList?.opinionAndAnalysis?.editorials?.length > 0">
      <h4>EDITORIALS</h4>
      <div class="underline"></div>
      <a [routerLink]="['/news-detail',formatTitleForUrl(newsList?.opinionAndAnalysis?.editorials[0]?.title), newsList?.opinionAndAnalysis?.editorials[0]?._id]">
        <div>
        <div class="opt-main">
          <img
            src="{{
              newsList?.opinionAndAnalysis?.editorials[0]?.images[0]?.imageUrl
                ? newsList?.opinionAndAnalysis?.editorials[0]?.images[0]?.imageUrl
                : '/assets/image/no-image.png'
            }}"
            class="card-img-top"
            alt="..."
          />
        </div>
        <div class="card-body">
         <a [routerLink]="['/news-detail',formatTitleForUrl(newsList?.opinionAndAnalysis?.editorials[0]?.title), newsList?.opinionAndAnalysis?.editorials[0]?._id]">
          <h5
          class="card-title fw-bold topic my-3">
          {{ (newsList?.opinionAndAnalysis?.editorials[0]?.title | slice: 0 : 60) + '...' }}
        </h5>
         </a>
          <a [routerLink]="['/view-profile',newsList?.opinionAndAnalysis?.editorials[0]?.createdBy?._id]">
            <p class="card-text topic">
              <small class="text-muted">
                <i class="fa fa-clock-o" aria-hidden="true"></i>
                {{ newsList?.opinionAndAnalysis?.editorials[0]?.createdAt | date: 'MMMM d, h:mma' }} &nbsp; By
                {{ newsList?.opinionAndAnalysis?.editorials[0]?.createdBy?.firstName + ' ' + newsList?.opinionAndAnalysis?.editorials[0]?.createdBy?.lastName }}</small
              >
            </p>
          </a>
        </div>
      </div>
      </a>
      <!-- <hr /> -->
      <div *ngFor="let editorials of newsList?.opinionAndAnalysis?.editorials; let i = index">
        <div class="d-flex align-items-center">
         <a [routerLink]="['/news-detail',formatTitleForUrl(editorials.title), editorials._id]">
          <div
          class="flex-grow-1 fw-bold topic"
          *ngIf="i > 0 && i < 4"        >
          {{ (editorials.title | slice: 0 : 60) + '...' }}
        </div>
         </a>
          <div class="flex-shrink-0" *ngIf="i > 0 && i < 4">
            <a [routerLink]="['/news-detail',formatTitleForUrl(editorials.title), editorials._id]">
              <div class="middle-image opt-img">
                <img
                  src="{{
                    editorials.images[0]?.imageUrl ? editorials.images[0]?.imageUrl : '/assets/image/no-image.png'
                  }}"
                  class="card-img-top"
                  width="100%"
                  height="100%"
                  alt="..."
                />
              </div>
            </a>
          </div>
        </div>
        <hr />
      </div>
      <a [routerLink]="['/view-more',{categoryName:'',subCategory:'EDITORIALS'}]">
        <p class="goto-news topic">
          Go to Editorials
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="13"
            height="13"
            fill="currentColor"
            class="bi bi-caret-right-fill"
            viewBox="0 0 16 16"
          >
            <path
              d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"
            />
          </svg>
        </p>
      </a>
    </div>
    <div class="col-sm-12 col-md-6 col-lg-3" *ngIf="newsList?.opinionAndAnalysis?.podcasts?.length > 0">
      <h4>PODCASTS</h4>
      <div class="underline"></div>
      <a [routerLink]="['/news-detail',formatTitleForUrl( newsList?.opinionAndAnalysis?.podcasts[0]?.title),  newsList?.opinionAndAnalysis?.podcasts[0]?._id]">
        <div>
        <div class="opt-main">
          <img
            src="{{
              newsList?.opinionAndAnalysis?.podcasts[0]?.images[0]?.imageUrl
                ? newsList?.opinionAndAnalysis?.podcasts[0]?.images[0]?.imageUrl
                : '/assets/image/no-image.png'
            }}"
            class="card-img-top"
            alt="..."
          />
        </div>
        <div class="card-body">
          <a [routerLink]="['/news-detail',formatTitleForUrl( newsList?.opinionAndAnalysis?.podcasts[0]?.title),  newsList?.opinionAndAnalysis?.podcasts[0]?._id]">
            <h5
            class="card-title fw-bold topic my-3">
            {{ (newsList?.opinionAndAnalysis?.podcasts[0]?.title | slice: 0 : 60) + '...' }}
          </h5>
          </a>
          <a [routerLink]="['/view-profile',newsList?.opinionAndAnalysis?.podcasts[0]?.createdBy?._id]">
            <p class="card-text topic">
              <small class="text-muted">
                <i class="fa fa-clock-o" aria-hidden="true"></i>
                {{ newsList?.opinionAndAnalysis?.podcasts[0]?.createdAt | date: 'MMMM d, h:mma' }} &nbsp; By
                {{ newsList?.opinionAndAnalysis?.podcasts[0]?.createdBy?.firstName + ' ' + newsList?.opinionAndAnalysis?.podcasts[0]?.createdBy?.lastName }}</small
              >
            </p>
          </a>
        </div>
      </div>
      </a>
      <!-- <hr /> -->
      <div *ngFor="let podcasts of newsList?.opinionAndAnalysis?.podcasts; let i = index">
        <div class="d-flex align-items-center">
          <a [routerLink]="['/news-detail',formatTitleForUrl( podcasts.title),  podcasts._id]">
            <div
            class="flex-grow-1 fw-bold topic"
            *ngIf="i > 0 && i < 4"
          >
            {{ (podcasts.title | slice: 0 : 60) + '...' }}
          </div>
          </a>
          <div class="flex-shrink-0" *ngIf="i > 0 && i < 4">
          <a [routerLink]="['/news-detail',formatTitleForUrl( podcasts.title),  podcasts._id]">
            <div class="middle-image opt-img">
              <img
                src="{{ podcasts.images[0]?.imageUrl ? podcasts.images[0]?.imageUrl : '/assets/image/no-image.png' }}"
                class="card-img-top"
                width="100%"
                height="100%"
                alt="..."
              />
            </div>
          </a>
          </div>
        </div>
        <hr />
      </div>
      <a [routerLink]="['/view-more',{categoryName:'',subCategory:'PODCASTS'}]">
        <p class="goto-news topic">
          Go to Podcasts
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="13"
            height="13"
            fill="currentColor"
            class="bi bi-caret-right-fill"
            viewBox="0 0 16 16"
          >
            <path
              d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"
            />
          </svg>
        </p>
      </a>
    </div>
  </div>
  <p class="h-line border-bottom my-5"></p>
  <hr />
  <div class="row">
    <div class="col-sm-12 col-md-6 col-lg-3 border-end" *ngIf="newsList?.other?.business?.length > 0">
      <h4>BUSINESS</h4>
      <div class="underline"></div>
      <a [routerLink]="['/news-detail',formatTitleForUrl(  newsList?.other?.business[0]?.title),   newsList?.other?.business[0]?._id]">
        <div
        class="border-0"
      >
        <div class="opt-main">
          <img
            src="{{
              newsList?.other?.business[0]?.images[0]?.imageUrl
                ? newsList?.other?.business[0]?.images[0]?.imageUrl
                : '/assets/image/no-image.png'
            }}"
            class="card-img-top"
            alt="..."
          />
        </div>
        <div class="card-body">
          <a [routerLink]="['/news-detail',formatTitleForUrl(  newsList?.other?.business[0]?.title),   newsList?.other?.business[0]?._id]">
            <h5
            class="card-title fw-bold topic my-3"
          >
            {{ (newsList?.other?.business[0]?.title | slice: 0 : 60) + '...' }}
          </h5>
          </a>
          <a [routerLink]="['/view-profile',newsList?.other?.business[0]?.createdBy?._id]">
            <p class="card-text topic">
              <small class="text-muted">
                <i class="fa fa-clock-o" aria-hidden="true"></i>
                {{ newsList?.other?.business[0]?.createdAt | date: 'MMMM d, h:mma' }} &nbsp; By
                {{ newsList?.other?.business[0]?.createdBy?.firstName + ' ' + newsList?.other?.business[0]?.createdBy?.lastName }}</small
              >
            </p>
          </a>
        </div>
      </div>
      </a>
      <!-- <hr /> -->
      <div *ngFor="let business of newsList?.other?.business; let i = index">
        <div class="d-flex align-items-center">
         <a [routerLink]="['/news-detail',formatTitleForUrl(business?.title),business?._id]">
          <div
          class="flex-grow-1 fw-bold topic"
          *ngIf="i > 0 && i < 4"
        >
          {{ (business.title | slice: 0 : 60) + '...' }}
        </div>
         </a>
          <div class="flex-shrink-0" *ngIf="i > 0 && i < 4">
            <a [routerLink]="['/news-detail',formatTitleForUrl(business?.title),business?._id]">
              <div class="middle-image opt-img">
                <img
                  src="{{ business.images[0]?.imageUrl ? business.images[0]?.imageUrl : '/assets/image/no-image.png' }}"
                  class="card-img-top"
                  width="100%"
                  height="100%"
                  alt="..."
                />
              </div>
            </a>
          </div>
        </div>
        <hr />
      </div>
      <a [routerLink]="['/view-more',{categoryName:'',subCategory:'BUSINESS'}]">
        <p class="goto-news topic" >
          Go to Business
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="13"
            height="13"
            fill="currentColor"
            class="bi bi-caret-right-fill"
            viewBox="0 0 16 16"
          >
            <path
              d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"
            />
          </svg>
        </p>
      </a>
    </div>
    <div class="col-sm-12 col-md-6 col-lg-3 border-end" *ngIf="newsList?.other?.defenceAndSecurity?.length > 0">
      <h4>DEFENCE & SECURITY</h4>
      <div class="underline"></div>
      <a [routerLink]="['/news-detail',formatTitleForUrl( newsList?.other?.defenceAndSecurity[0]?.title), newsList?.other?.defenceAndSecurity[0]?._id]">
        <div>
        <div class="opt-main">
          <img
            src="{{
              newsList?.other?.defenceAndSecurity[0]?.images[0]?.imageUrl
                ? newsList?.other?.defenceAndSecurity[0]?.images[0]?.imageUrl
                : '/assets/image/no-image.png'
            }}"
            class="card-img-top"
            alt="..."
          />
        </div>
        <div class="card-body">
          <a [routerLink]="['/news-detail',formatTitleForUrl( newsList?.other?.defenceAndSecurity[0]?.title), newsList?.other?.defenceAndSecurity[0]?._id]">
            <h5
            class="card-title fw-bold topic my-3">
            {{ (newsList?.other?.defenceAndSecurity[0]?.title | slice: 0 : 60) + '...' }}
          </h5>
          </a>
          <a [routerLink]="['/view-profile',newsList?.other?.defenceAndSecurity[0].createdBy?._id]">
            <p class="card-text topic">
              <small class="text-muted">
                <i class="fa fa-clock-o" aria-hidden="true"></i>
                {{ newsList?.other?.defenceAndSecurity[0]?.createdAt | date: 'MMMM d, h:mma' }} &nbsp; By
                {{ newsList?.other?.defenceAndSecurity[0]?.createdBy?.firstName + ' ' + newsList?.other?.defenceAndSecurity[0]?.createdBy?.lastName }}</small
              >
            </p>
          </a>
        </div>
      </div>
      </a>
      <!-- <hr /> -->
      <div *ngFor="let defence of newsList?.other?.defenceAndSecurity; let i = index">
        <div class="d-flex align-items-center">
          <a [routerLink]="['/news-detail',formatTitleForUrl(defence?.title),defence?._id]">
            <div
            class="flex-grow-1 fw-bold topic"
            *ngIf="i > 0 && i < 4"
          >
            {{ (defence.title | slice: 0 : 60) + '...' }}
          </div>
          </a>
          <div class="flex-shrink-0" *ngIf="i > 0 && i < 4">
            <a [routerLink]="['/news-detail',formatTitleForUrl(defence?.title),defence?._id]">
              <div class="middle-image opt-img">
                <img
                  src="{{ defence.images[0]?.imageUrl ? defence.images[0]?.imageUrl : '/assets/image/no-image.png' }}"
                  class="card-img-top"
                  alt="..."
                  width="100%"
                  height="100%"
                />
              </div>
            </a>
          </div>
        </div>
        <hr />
      </div>
      <a [routerLink]="['/view-more',{categoryName:'',subCategory:'DEFENCE & SECURITY'}]">
        <p class="goto-news topic">
          Go to Defence & Security
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="13"
            height="13"
            fill="currentColor"
            class="bi bi-caret-right-fill"
            viewBox="0 0 16 16"
          >
            <path
              d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"
            />
          </svg>
        </p>
      </a>
    </div>
    <div class="col-sm-12 col-md-6 col-lg-3 border-end" *ngIf="newsList?.other?.sports?.length > 0">
      <h4>SPORTS</h4>
      <div class="underline"></div>
     <a [routerLink]="['/news-detail',formatTitleForUrl(newsList?.other?.sports[0]?.title),newsList?.other?.sports[0]?._id]">
      <div>
      <div class="opt-main">
        <img
          src="{{
            newsList?.other?.sports[0]?.images[0]?.imageUrl
              ? newsList?.other?.sports[0]?.images[0]?.imageUrl
              : '/assets/image/no-image.png'
          }}"
          class="card-img-top"
          alt="..."
        />
      </div>
      <div class="card-body">
        <a [routerLink]="['/news-detail',formatTitleForUrl(newsList?.other?.sports[0]?.title),newsList?.other?.sports[0]?._id]">
          <h5
          class="card-title fw-bold topic my-3"
        >
          {{ (newsList?.other?.sports[0]?.title | slice: 0 : 60) + '...' }}
        </h5>
        </a>
       <a [routerLink]="['/view-profile',newsList?.other?.sports[0]?.createdBy?._id]">
        <p class="card-text topic">
          <small class="text-muted">
            <i class="fa fa-clock-o" aria-hidden="true"></i>
            {{ newsList?.other?.sports[0]?.createdAt | date: 'MMMM d, h:mma' }} &nbsp; By
            {{ newsList?.other?.sports[0]?.createdBy?.firstName + ' ' + newsList?.other?.sports[0]?.createdBy?.lastName}}</small
          >
        </p>
       </a>
      </div>
    </div>
     </a>
      <!-- <hr /> -->
      <div *ngFor="let sports of newsList?.other?.sports; let i = index">
        <div class="d-flex align-items-center">
          <a [routerLink]="['/news-detail',formatTitleForUrl(sports?.title),sports?._id]">
            <div
            class="flex-grow-1 fw-bold topic"
            *ngIf="i > 0 && i < 4"
          >
            {{ (sports.title | slice: 0 : 60) + '...' }}
          </div>
          </a>
          <div class="flex-shrink-0" *ngIf="i > 0 && i < 4">
           <a [routerLink]="['/news-detail',formatTitleForUrl(sports?.title),sports?._id]">
            <div class="middle-image opt-img">
              <img
                src="{{ sports.images[0]?.imageUrl ? sports.images[0]?.imageUrl : '/assets/image/no-image.png' }}"
                class="card-img-top"
                width="100%"
                height="100%"
                alt="..."
              />
            </div>
           </a>
          </div>
        </div>
        <hr />
      </div>
      <a [routerLink]="['/view-more',{categoryName:'',subCategory:'SPORTS'}]">
        <p class="goto-news topic">
          Go to Sports
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="13"
            height="13"
            fill="currentColor"
            class="bi bi-caret-right-fill"
            viewBox="0 0 16 16"
          >
            <path
              d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"
            />
          </svg>
        </p>
      </a>
    </div>
    <div class="col-sm-12 col-md-6 col-lg-3" *ngIf="newsList?.other?.entertainment?.length > 0">
      <h4>ENTERTAINMENT</h4>
      <div class="underline"></div>
     <a [routerLink]="['/news-detail',formatTitleForUrl(newsList?.other?.entertainment[0]?.title),newsList?.other?.entertainment[0]?._id]">
      <div>
      <div class="opt-main">
        <img
          src="{{
            newsList?.other?.entertainment[0]?.images[0]?.imageUrl
              ? newsList?.other?.entertainment[0]?.images[0]?.imageUrl
              : '/assets/image/no-image.png'
          }}"
          class="card-img-top"
          alt="..."
        />
      </div>
      <div class="card-body">
        <a [routerLink]="['/news-detail',formatTitleForUrl(newsList?.other?.entertainment[0]?.title),newsList?.other?.entertainment[0]?._id]">
          <h5
          class="card-title fw-bold topic my-3"
        >
          {{ (newsList?.other?.entertainment[0]?.title | slice: 0 : 60) + '...' }}
        </h5>
        </a>
        <a [routerLink]="['/view-profile',newsList?.other?.entertainment[0]?.createdBy?._id]">
          <p class="card-text topic">
            <small class="text-muted">
              <i class="fa fa-clock-o" aria-hidden="true"></i>
              {{ newsList?.other?.entertainment[0]?.createdAt | date: 'MMMM d, h:mma' }} &nbsp; By
              {{ newsList?.other?.entertainment[0]?.createdBy?.firstName + ' ' + newsList?.other?.entertainment[0]?.createdBy?.lastName }}</small
            >
          </p>
        </a>
      </div>
    </div>
     </a>
      <!-- <hr /> -->
      <div *ngFor="let entertainment of newsList?.other?.entertainment; let i = index">
        <div class="d-flex align-items-center">
          <a [routerLink]="['/news-detail',formatTitleForUrl(entertainment?.title),entertainment?._id]">
            <div
            class="flex-grow-1 fw-bold topic"
            *ngIf="i > 0 && i < 4"
          >
            {{ (entertainment.title | slice: 0 : 60) + '...' }}
          </div>
          </a>
          <div class="flex-shrink-0" *ngIf="i > 0 && i < 4">
           <a [routerLink]="['/news-detail',formatTitleForUrl(entertainment?.title),entertainment?._id]">
            <div class="middle-image opt-img">
              <img
                src="{{
                  entertainment.images[0]?.imageUrl ? entertainment.images[0]?.imageUrl : '/assets/image/no-image.png'
                }}"
                class="card-img-top"
                width="100%"
                height="100%"
                alt="..."
              />
            </div>
           </a>
          </div>
        </div>
        <hr />
      </div>
      <a [routerLink]="['/view-more',{categoryName:'',subCategory:'ENTERTAINMENT'}]">
        <p class="goto-news topic">
          Go to Entertainment
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="13"
            height="13"
            fill="currentColor"
            class="bi bi-caret-right-fill"
            viewBox="0 0 16 16"
          >
            <path
              d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"
            />
          </svg>
        </p>
      </a>
    </div>
  </div>

  <p class="h-line border-bottom my-5"></p>
  <h3>VIDEO</h3>
  <div class="video-line"></div>
  <div class="row my-3">
    <div class="col-sm-12 col-md-6 col-lg-6">
      <div class="video" *ngIf="newsList?.video[0]?.videoUrl">
        <ng-container>
          <youtube-player class="video video-size" [videoId]="getVideoId(newsList?.video[0]?.videoUrl)"></youtube-player>
        </ng-container>
        <h5 class="fw-bold topic">
          <a [routerLink]="['/news-detail', formatTitleForUrl(newsList?.video[0]?.title), newsList?.video[0]?._id]">
            {{ newsList?.video[0]?.title }}
          </a>
        </h5>
        <p [innerHTML]="(newsList?.video[0]?.content | slice: 0 : 150) + (newsList?.video[0]?.content.length > 150 ? '...' : '')"></p>
      </div>
      <button type="button" class="btn btn-outline-warning my-2" [routerLink]="['/view-more', { category: 'VIDEO' }]">View More</button>
    </div>
    <div class="col-sm-12 col-md-6 col-lg-6 border-start border-3">
      <ul class="nav nav-fill nav-tabs" role="tablist">
        <li class="nav-item" role="presentation">
          <a class="nav-link active" id="fill-tab-0" data-bs-toggle="tab" href="#fill-tabpanel-0" role="tab" aria-controls="fill-tabpanel-0" aria-selected="true">History & Culture</a>
        </li>
        <li class="nav-item" role="presentation">
          <a class="nav-link" id="fill-tab-1" data-bs-toggle="tab" href="#fill-tabpanel-1" role="tab" aria-controls="fill-tabpanel-1" aria-selected="false">Religious</a>
        </li>
        <li class="nav-item" role="presentation">
          <a class="nav-link" id="fill-tab-2" data-bs-toggle="tab" href="#fill-tabpanel-2" role="tab" aria-controls="fill-tabpanel-2" aria-selected="false">Crime</a>
        </li>
      </ul>
      <div class="tab-content" id="tab-content">
        <div class="tab-pane active" id="fill-tabpanel-0" role="tabpanel" aria-labelledby="fill-tab-0">
          <div class="d-flex my-2" *ngFor="let history of newsList?.other?.historyAndCulture.slice(0, 4)">
            <div class="flex-shrink-0 bottom-slider">
              <a [routerLink]="['/news-detail',formatTitleForUrl(history?.title), history?._id]">
                <img src="{{ history?.images[0]?.imageUrl ? history?.images[0]?.imageUrl : '/assets/image/no-image.png' }}" class="img-fluid" alt="..." />
              </a>
            </div>
            <div class="flex-grow-1 ms-3 fw-bold topic">
              <a [routerLink]="['/news-detail',formatTitleForUrl( history?.title), history?._id]">
                {{ (history?.title | slice: 0 : 40) + '...' }}
              </a>
              <p class="my-3 video-content" [innerHTML]="(history?.content | slice: 0 : 90) + (history?.content.length > 90 ? '...' : '')"></p>
            </div>
          </div>
          <div class="border-top text-center show-more-news topic" [routerLink]="['/view-more', { category: 'History & Culture' }]">Read More News</div>
        </div>
        <div class="tab-pane" id="fill-tabpanel-1" role="tabpanel" aria-labelledby="fill-tab-1">
          <div class="d-flex my-2" *ngFor="let religious of newsList?.other?.religious.slice(0, 4)">
            <div class="flex-shrink-0 bottom-slider">
              <a [routerLink]="['/news-detail',formatTitleForUrl( religious?.title), religious?._id]">
                <img src="{{ religious?.images[0]?.imageUrl ? religious?.images[0]?.imageUrl : '/assets/image/no-image.png' }}" class="img-fluid" alt="..." />
              </a>
            </div>
            <div class="flex-grow-1 ms-3 fw-bold topic">
              <a [routerLink]="['/news-detail',formatTitleForUrl(religious?.title), religious?._id]">
                {{ (religious?.title | slice: 0 : 40) + '...' }}
              </a>
              <p class="my-3 video-content" [innerHTML]="(religious?.content | slice: 0 : 90) + (religious?.content.length > 90 ? '...' : '')"></p>
            </div>
          </div>
          <div class="border-top text-center show-more-news topic" [routerLink]="['/view-more', { category: 'Religious' }]">Read More News</div>
        </div>
        <div class="tab-pane" id="fill-tabpanel-2" role="tabpanel" aria-labelledby="fill-tab-2">
          <div class="d-flex my-2" *ngFor="let crime of newsList?.other?.crime.slice(0, 4)">
            <div class="flex-shrink-0 bottom-slider">
              <a [routerLink]="['/news-detail',formatTitleForUrl(crime?.title), crime?._id]">
                <img src="{{ crime?.images[0]?.imageUrl ? crime?.images[0]?.imageUrl : '/assets/image/no-image.png' }}" class="img-fluid" alt="..." />
              </a>
            </div>
            <div class="flex-grow-1 ms-3 fw-bold topic">
              <a [routerLink]="['/news-detail',formatTitleForUrl(crime?.title), crime?._id]">
                {{ (crime?.title | slice: 0 : 40) + '...' }}
              </a>
              <p class="my-3 video-content" [innerHTML]="(crime?.content | slice: 0 : 90) + (crime?.content.length > 90 ? '...' : '')"></p>
            </div>
          </div>
          <div class="border-top text-center show-more-news topic" [routerLink]="['/view-more', { category: 'Crime' }]">Read More News</div>
        </div>
      </div>
    </div>
  </div>
  
</div>
