<div class="container">
    
    <h1>Privacy Policy</h1>
    <div class="border-line my-2"></div>
    <div class="row">
        <!-- <div class="col-sm-12 col-md-12 col-lg-5">
            <div class="about-image">
                <img src="assets/image/about.jpg" alt="" width="100%">
            </div>
        </div> -->
        <div class="col-sm-12 col-md-12">
            <p>Thank you for visiting our website of South Asian Digest. We respect your privacy and consider it an important element of our business. This policy describes the information that we collect from you when you download, access or use its website for delivery of information, products, offerings and content via any mobile or internet connected device or otherwise.</p>

            <p>We cover a wide range of topics, including politics, business, culture, sports, health, science, and entertainment. We also feature opinions and analyses from experts and commentators on various issues that affect the global community.</p>
            
            <h3>User consent</h3>
            <p>By using services of the websites of South Asian Digest (for example, when you register for an account, communicate with us, on our sites or applications or through social media), you agree and provide consent to our collection, use and sharing of your personal information as described in this policy.</p>
            <h3>Information Collected and Stored Automatically</h3>
            <p>If you visit our website(s) just to browse, read pages or download information, but do not register for any of our services, we gather and store certain information about your visit automatically. This information cannot and does not identify you personally.
            The kind of information that is gathered automatically include the type of browser you are using (e.g., Netscape, Internet Explorer), the type of Operating System you are using (e.g., Windows ’98 or Mac OS) and the domain name of your Internet Service Provider (e.g., America Online, Satyam Online, Mantra Online, VSNL), the date and time of your visit and the pages you visit, along with geographical information such as Country, Region or City, etc. We sometimes use this non-personally identifiable information to improve our website(s) design, content and primarily to give you a better browsing experience.
            </p>
            <h3>Embedded content from other websites</h3>
           <p>Articles on this site may include embedded content (e.g. videos, images, articles, etc.). Embedded content from other websites behaves in the exact same way as if the visitor has visited the other website.            
            These websites may collect data about you, use cookies, embed additional third-party tracking, and monitor your interaction with that embedded content, including tracing your interaction with the embedded content if you have an account and are logged in to that website.</p>
            <h3>Contact information</h3>
            <p>For further details, please get in touch with us- contact&#64;southasiandigest.com</p>
        </div>
    </div>
</div>