<div class="container">
    
    <h1>About Us</h1>
    <div class="border-line my-2"></div>
    <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-5">
            <div class="about-image">
                <img src="/assets/image/about-us.jpg" alt="" width="100%">
            </div>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-7">
            <p>South Asian Digest is a news website that aims to provide unbiased and reliable news from all over the world. We are a team of journalists, researchers, and editors who are passionate about delivering factual and informative stories to our readers.</p>

            <p>We cover a wide range of topics, including politics, business, culture, sports, health, science, and entertainment. We also feature opinions and analyses from experts and commentators on various issues that affect the global community.</p>
            
            <p>Our mission is to uphold the highest standards of journalism and to serve as a platform for diverse voices and perspectives. We believe that news should be based on self-research and fact-checking, not on rumors or biases. We strive to be accurate, fair, balanced, and transparent in our reporting.</p>
            
            <p>We value your feedback and suggestions. If you have any questions, comments, or concerns, please feel free to contact us at contact&#64;southasiandigest.com. You can also follow us on Facebook, Twitter, and Instagram for the latest updates.</p>
            
            <p>Thank you for choosing South Asian Digest as your source of news. We hope you enjoy reading our stories as much as we enjoy writing them.</p>
        </div>
    </div>
</div>