
import { Component, ElementRef, ViewChild ,ViewEncapsulation} from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { DomSanitizer, Meta, Title } from '@angular/platform-browser'
import { HomeService } from 'src/app/shared/services/home.service'
import Swal from 'sweetalert2';

@Component({
  selector: 'app-news-detail',
  templateUrl: './news-detail.component.html',
  styleUrls: ['./news-detail.component.scss'],
  // encapsulation:ViewEncapsulation.None
})
export class NewsDetailComponent {
  newsId: any
  showData: any
  role: any
  newsTitle: any
  userId:string = '';
  commentsList: any = [];

  currentPage: number = 1; // Current page
  itemsPerPage: number = 5; // Number of items per page
  totalPages: number = 0; // Total number of pages
  pages: number[] = [];
  // path : any = "/assets/test.pdf"
  // path : any = ""

  httpHeaders = {
    'Access-Control-Allow-Origin':'*'
  }
  

  
 @ViewChild('comment') comment! : ElementRef<any>
  constructor(
    public route: ActivatedRoute,
    public _homeService: HomeService,
    private sanitizer: DomSanitizer,
    public router : Router
  ) {}

  ngOnInit(): void {
    // this.newsId = this.route.snapshot.params['_id']
    this.userId = localStorage.getItem('SAWebUserId') || '';
    this.route.paramMap.subscribe({
      next: (params) => {
        if (params.get('_id')) {
          this.newsId = params.get('_id')
        }
        if (params.get('title')) {
          this.newsTitle = params.get('title')
        }
        this._homeService.getNewsById(this.newsId).subscribe({
          next: (res: any) => {
            if (res.success === true) {
              this.showData = res.data[0]
              // this.path = this.showData?.pdf?.pdfUrl;
              const title = this.showData.metaData.title
              const description = this.showData.metaData.description
              const keywords = this.showData.metaData.tag
              this._homeService.setTitleAndMetaTags(title, description, keywords,this.showData?.images[0])
            }
          },
          error: (err) => {
            console.log('error', err)
          },
        })
        // this.getNewsById()
        this.getCommentByNewsId(this.newsId);
      },
    })
  }

  getNewsById() {
    this._homeService.getNewsById(this.newsId).subscribe({
      next: (res: any) => {
        if (res.success === true) {
          this.showData = res.data[0]
          // this.path = this.showData?.pdf?.pdfUrl;
          const title = this.showData.metaData.title
          const description = this.showData.metaData.description
          const keywords = this.showData.metaData.tag
          this._homeService.setTitleAndMetaTags(title, description, keywords,this.showData?.images[0])
        }
      },
      error: (err) => {
        console.log('error', err)
      },
    })
  }

  getVideoId(url: string): string {
    const urlParams = new URLSearchParams(new URL(url).search)
    return urlParams.get('v') || ''
  }

  gotoProfile(id:any) {
    this.router.navigate(['view-profile',id])
  }

  gotoNewsDetail(newsId: any, newsTitle: string) {
    this.router.navigate(['news-detail', this.formatTitleForUrl(newsTitle), newsId])
  }

  formatTitleForUrl(title: string): string {
    if(title)return title.replace(/\s+/g, '-').toLowerCase()
    return ''
  }
  addComment(){
    if(this.userId){
      if(this.comment.nativeElement.value.trim() !== '') {
        let comment = {
          _id : this.newsId,
          commentedBy: this.userId,
          comment: this.comment.nativeElement.value
        }
        this._homeService.addComment(comment).subscribe({
          next:(res:any)=>{
            if(res.success){
              this.comment.nativeElement.value= ''
              this.getCommentByNewsId(this.newsId);
            }
          }
        })
      } else {
        Swal.fire({
          title: 'Comment cannot be blank',
          icon: 'warning',
        })
      }
    } else {
      Swal.fire({
        title: 'Please Subscribe SOUTH ASIAN DIGEST ',
        icon: 'warning',
      })
    }
  }
  

  getCommentByNewsId(newsId:string){
      if(newsId){
        this._homeService.getCommentsById(newsId, this.currentPage, this.itemsPerPage).subscribe({
          next:(res:any)=>{
            if(res.success){
              this.commentsList = res.data.comments.map((item:any)=>{
                item.avatar = this.sanitizer.bypassSecurityTrustUrl(item.avatar);
                return item
              })
              this.totalPages = Math.ceil(res.data.counts / this.itemsPerPage);
              // Generate array of page numbers
              this.pages = Array.from({ length: this.totalPages }, (_, i) => i + 1);
            }
          },
          error: (err:any)=>{
            console.log(err);
          }
        })
      }
  }

  deleteCommentById(commentId:string){
    if(commentId){
      Swal.fire({
        // title: 'Please Subscribe SOUTH ASIAN DIGEST ',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Delete',
        cancelButtonText:'NO'
    }).then((res)=>{
        if(res.value){
          this._homeService.deleteCommentByCommentId(this.newsId, commentId).subscribe({
            next:(res:any)=>{
              this.getCommentByNewsId(this.newsId);
            }
          })
        }
      })
      
    }
  }

  setPage(page: number): void {
    // Check if page is within valid range
    if (page >= 1 && page <= this.totalPages) {
      this.currentPage = page;
      this.getCommentByNewsId(this.newsId)
    }
  }

  prevPage(): void {
    if (this.currentPage > 1) {
      this.currentPage--;
      this.getCommentByNewsId(this.newsId)
    }
  }

  nextPage(): void {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
      this.getCommentByNewsId(this.newsId)
    }
  }
}
