import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit {
  ngOnInit(): void {
    // if (!this.isScriptLoaded) {
    //   const tag = document.createElement('script')
    //   tag.src = `<script src="https://www.youtube.com/iframe_api"></script>`
    //   document.body.appendChild(tag)
    //   this.isScriptLoaded = true
    // }
  }

  isScriptLoaded = false
}
