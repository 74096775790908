import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { HomeComponent } from './home/home.component'
import { LayoutComponent } from './layout.component'
import { NewsDetailComponent } from './news-detail/news-detail.component'
import { ViewMoreComponent } from './view-more/view-more.component'
import { ViewProfileComponent } from './view-profile/view-profile.component'
import { AboutUsComponent } from './about-us/about-us.component'
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component'

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: '',
        component: HomeComponent,
      },
      {
        path: 'news-detail',
        component: NewsDetailComponent,
      },
      {
        path: 'view-more',
        component: ViewMoreComponent,
      },
      // {
      //   path: 'view-more',
      //   component: ViewMoreComponent,
      // },
      {
        path: 'view-more/:categoryName',
        component: ViewMoreComponent,
      },
      {
        path: 'view-more/:categoryName/:subCategory',
        component: ViewMoreComponent,
      },
      {
        path: 'news-detail/:title/:_id',
        component: NewsDetailComponent,
      },
      {
        path: 'view-profile/:_id',
        component: ViewProfileComponent,
      },
      {
        path: 'about-us',
        component: AboutUsComponent,
      },
      {
        path: 'privacy-policy',
        component: PrivacyPolicyComponent,
      },
    ],
  },
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class LayoutRoutingModule {}
