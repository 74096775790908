import { Component, ElementRef, Renderer2, ViewChild } from '@angular/core'
import { Router } from '@angular/router'
import { HomeService } from '../../services/home.service'
import { LoaderService } from '../../services/loader.service'
declare var handleSignout :any
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  constructor(
    private _router: Router,
    private _renderer: Renderer2,
    public _homeService: HomeService,
    public router: Router,
    public loaderService: LoaderService
  ) {}

  worldNews: any = []
  opinion_analysis: any
  selectedSubSection: any = []
  subSectionList = [
    'FACT CHECK',
    'COLUMNS',
    'EDITORIALS',
    'PODCASTS',
    'BUSINESS',
    'DEFENCE & SECURITY',
    'SPORTS',
    'ENTERTAINMENT',
  ]
  isSearchClicked: boolean = false
  searchText: string = ''
  searchResult: any
  worldAccordionOpen = false;
  opinionAccordionOpen = false;
  publicationAccordionOpen = false;
  activityAccordionOpen = false
  otherAccordionOpen = false
  userId : string ='';
  userName: string ='';
  @ViewChild('googleSignInButton') googleSignInButton!: ElementRef<any>;

  ngOnInit(): void {
    // this.getNews()
    this.userId = localStorage.getItem('SAWebUserId') || ''
    // this.gotoViewMore('OPINIONS & ANALYSIS')
    this.getUserById()
    this.loaderService.userName.subscribe(name=>{
      this.userName = name
    })

  }
  
  getUserById(){
    if(this.userId){
      this._homeService.getUserById(this.userId).subscribe({
        next:(res:any)=>{
          if(res.success){
            this.userName = res.data.firstName;
            this.loaderService.userName.next(res.data.firstName) 
          }
        }
      })
    }
  }

  // gotoNewsDetail(newsId: any) {
  //   this.router.navigate(['news-detail', { _id: newsId }])
  //   this.searchResult = null
  //   this.searchText = ''
  //   this.toggleSearch()
  // }

  gotoNewsDetail(newsId: any, newsTitle: string) {
    this.router.navigate(['news-detail', this.formatTitleForUrl(newsTitle), newsId])
    this.searchResult = null
    this.searchText = ''
    this.toggleSearch()
    this.closeMobileNavigationMenu()
  }

  formatTitleForUrl(title: string): string {
    // Remove spaces and convert to lowercase, you can add more logic based on your requirements
    return title.replace(/\s+/g, '-').toLowerCase()
  }

  opt: string = ''
  @ViewChild('mobileMenu') mobileMenu!: ElementRef

  route() {
    this._router.navigate(['home'])
  }

  goto() {
    this._router.navigate(['']);
    this.closeMobileNavigationMenu();
  }

  openMobileNavigationMenu() {
    if (this.mobileMenu) {
      this._renderer.setStyle(this.mobileMenu.nativeElement, 'display', 'block')
    }
  }

  gotoViewMore(categoryName?: string, subCategory?: string) {
    // if (type == 'OPINIONS & ANALYSIS') {
    //   this._homeService.getNewsByTypeAndCategory(type).subscribe({
    //     next: (res: any) => {
    //       if (res.success === true) {
    //         this.opinion_analysis = res.data
    //         this.selectedSubSection = res.data
    //         this.filterSubSectionData('FACT CHECK')
    //       }
    //     },
    //     error: (err) => {
    //       console.log('error', err)
    //     },
    //   })
    // } else {
    this.router.navigate([
      'view-more',
      {
        // type: type ? type : '',
        categoryName: categoryName ? categoryName : '',
        subCategory: subCategory ? subCategory : '',
      },
    ])
    // }
  }

  gotoViewMoreMobile(categoryName?: string, subCategory?: string) {
    this.router.navigate([
      'view-more',
      {
        categoryName: categoryName ? categoryName : '',
        subCategory: subCategory ? subCategory : '',
      },
    ])
    this.closeMobileNavigationMenu();
  }

  toggleWorld() {
    this.worldAccordionOpen = !this.worldAccordionOpen;
  }

  toggleOpinion() {
    this.opinionAccordionOpen = !this.opinionAccordionOpen;
  }

  togglePublications() {
    this.publicationAccordionOpen = !this.publicationAccordionOpen;
  }
  
  toggleActivity() {
    this.activityAccordionOpen = !this.activityAccordionOpen;
  }

  otherActivity() {
    this.otherAccordionOpen = !this.otherAccordionOpen;
  }

  toggleSearch() {
    this.isSearchClicked = !this.isSearchClicked
  }

  gotoViewMoreSubSection(type: string, subSection?: any) {
    this.router.navigate(['view-more', { type: type, subSection: subSection }])
  }

  closeMobileNavigationMenu() {
    if (this.mobileMenu) {
      this._renderer.setStyle(this.mobileMenu.nativeElement, 'display', 'none')
    }
  }

  changeOpt(str: any) {
    this.opt = str
  }

  filterSubSectionData(selectedSubSection: string) {
    this.selectedSubSection = this.opinion_analysis.filter((item: any) => {
      return item.subSection == selectedSubSection
    })
  }

  searchContent(item: any) {
    this._homeService.getSuggestion(item).subscribe({
      next: (res: any) => {
        if (res.success == true) {
          this.searchResult = res.data
        }
      },
      error: (err) => {
        console.log('error', err)
      },
    })
  }

  logout(){
    if(this.userId || this.userName){
      handleSignout();
      localStorage.removeItem('loggedInUser')
      localStorage.removeItem('SAWebUserId')
      // this._router.navigate(['/']).then(()=>{
      //   window.location.reload();
      // });
    }

  }
 
  // getData() {
  //   console.log('Input field data:', this.searchText);
  // }
}
