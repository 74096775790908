<div class="header">
  <a [routerLink]="'/'" class="header-item">
    <img src="/assets/image/logo.png" alt="logo" />
  </a>
  <ul class="horizontal_header">
    <div class="common">
     <a href="/"><li>Home</li></a>
    </div>
    <div class="common">
      <a [routerLink]="['/view-more', { categoryName: 'INDIA'}]"><li>India</li></a>
    </div>
    <div class="common-factcheck">
      <a [routerLink]="['/view-more', { categoryName: 'INDIA', subCategory: 'FACT CHECK' }]"><li >Fact-Check</li></a>
    </div>
    <div class="common-world">
      <li class="has-submenu-world">
        World
        <i class="bi bi-caret-down-fill">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="10"
            height="10"
            fill="currentColor"
            class="bi bi-caret-down-fill"
            viewBox="0 0 16 16"
          >
            <path
              d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"
            />
          </svg>
        </i>
        <div class="submenu-world">
          <ul>
            <li (click)="gotoViewMore('', 'US')">US</li>
            <li (click)="gotoViewMore('', 'UK')">UK</li>
            <li (click)="gotoViewMore('', 'South Asia')">South Asia</li>
            <li (click)="gotoViewMore('', 'Russia')">Russia</li>
            <li (click)="gotoViewMore('', 'China')">China</li>
            <li (click)="gotoViewMore('', 'Africa')">Africa</li>
            <li (click)="gotoViewMore('', 'Europe')">Europe</li>
            <li (click)="gotoViewMore('', 'Middle East')">Middle East</li>
            <li (click)="gotoViewMore('', 'Rest of the World')">Rest of the World</li>
          </ul>
        </div>
        <!-- <div class="world-main w-100">
          <div class="row country mx-3">
            <div class="col-3 border-bottom my-0 country-name" (click)="gotoViewMore('','US')">US</div>
            <div class="col-3 border-bottom my-0 country-name" (click)="gotoViewMore('','UK')">UK</div>
            <div class="col-3 border-bottom my-0 country-name" (click)="gotoViewMore('','South Asia')">South Asia</div>
            <div class="col-3 border-bottom my-0 country-name" (click)="gotoViewMore('','Russia')">Russia</div>
            <div class="col-3 border-bottom my-2 country-name" (click)="gotoViewMore('','China')">China</div>
            <div class="col-3 border-bottom my-2 country-name" (click)="gotoViewMore('','Africa')">Africa</div>
            <div class="col-3 border-bottom my-2 country-name" (click)="gotoViewMore('','Europe')">Europe</div>
            <div class="col-3 border-bottom my-2 country-name" (click)="gotoViewMore('','Middle East')">Middle East</div>
            <div class="col-3 mb-1 country-name" (click)="gotoViewMore('','Rest of the World')">Rest of the World</div>
            <hr>
          </div>
          <div class="d-flex mt-4">
            <div class="mx-3" style="width: 18rem" *ngFor="let world of worldNews; let i = index">
              <div class="head-world" *ngIf="i >= 0 && i < 4">
                <img
                  src="{{ world?.images[0]?.imageUrl ? world?.images[0]?.imageUrl : 'assets/image/no-image.png' }}"
                  class="card-img-top"
                  alt="..."
                  (click)="gotoNewsDetail(world?._id, world?.title)"
                />
              </div>
              <div
                class="card-body topic world-body"
                *ngIf="i >= 0 && i < 4"
                (click)="gotoNewsDetail(world?._id, world?.title)"
              >
                <p class="card-text">{{ (world?.title | slice: 0 : 50) + '...' }}</p>
                <p class="card-text">{{ world?.createdAt | date: 'dd MMM, yyyy' }}</p>
              </div>
            </div>
          </div>
        </div> -->
      </li>
    </div>
    <a [routerLink]="['/view-more', { categoryName: '', subCategory: 'Politics' }]"><li>Politics</li></a>
    <div class="common-opinion">
      <li class="has-option-and-analysis">
        Opinions and Analysis
        <i class="bi bi-caret-down-fill">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="10"
            height="10"
            fill="currentColor"
            class="bi bi-caret-down-fill"
            viewBox="0 0 16 16"
          >
            <path
              d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"
            />
          </svg>
        </i>
        <div class="submenu-open-and-analysis">
          <ul>
            <li (click)="gotoViewMore('', 'COLUMNS')">Columns</li>
            <li (click)="gotoViewMore('', 'EDITORIALS')">Editorials</li>
          </ul>
        </div>
        <!-- <div class="sub-cat w-100">
          <div class="row">
            <div class="col-sm-2 category">
              <ul>
                <div class="border-end fw-bold opinion-head">Category</div>
                <div class="border-end left-sec" *ngFor="let item of subSectionList">
                  <li (click)="filterSubSectionData(item)">{{ item }}</li>
                </div>
              </ul>
            </div>
            <div class="col-9 d-flex opt-cont">
              <div class="col-3" *ngFor="let data of selectedSubSection; let i = index">
                <div class="mx-1">
                  <div *ngIf="i < 4">
                    <div class="opt-main">
                      <img src="{{ data?.images[0]?.imageUrl }}" style="width: 100%" class="card-img-top" alt="..." />
                    </div>
                    <div class="card-body topic" (click)="gotoNewsDetail(data?._id, data?.title)">
                      <div class="d-flex justify-content-between opacity-50 align-items-end item-cont">
                        <p>{{ data?.createdAt | date: 'h:mma' }}</p>
                        <p>{{ data?.createdAt | date: 'dd MMM, yyyy' }}</p>
                      </div>
                      <h5 class="card-title fw-bold topic">{{ (data?.title | slice: 0 : 60) + '...' }}</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> -->
      </li>
    </div>
    <div class="common-publications">
      <li class="has-submenu-publications">
        Publications
        <i class="bi bi-caret-down-fill">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="10"
            height="10"
            fill="currentColor"
            class="bi bi-caret-down-fill"
            viewBox="0 0 16 16"
          >
            <path
              d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"
            />
          </svg>
        </i>
        <div class="submenu-publications">
          <ul>
            <li (click)="gotoViewMore('', 'Reports')">Reports</li>
            <li (click)="gotoViewMore('', 'e-Magazines')">E-magazines</li>
          </ul>
        </div>
      </li>
    </div>
    <div class="common-activity">
      <li class="has-submenu">
        Activities
        <i class="bi bi-caret-down-fill">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="10"
            height="10"
            fill="currentColor"
            class="bi bi-caret-down-fill"
            viewBox="0 0 16 16"
          >
            <path
              d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"
            />
          </svg>
        </i>
        <div class="submenu">
          <ul>
            <a [routerLink]="['/view-more', { categoryName: '', subCategory: 'Seminars' }]">
              <li>Seminars</li>
            </a>
            
            <a [routerLink]="['/view-more', { categoryName: '', subCategory: 'Discussions' }]">
              <li>Discussions</li>
            </a>
            
            <a [routerLink]="['/view-more', { categoryName: '', subCategory: 'Conferences' }]">
              <li>Conferences</li>
            </a>
          </ul>
        </div>
      </li>
    </div>
    <div class="common-other">
      <li class="has-submenu-other">
        <i class="fa fa-ellipsis-h" aria-hidden="true"></i>
        <div class="submenu-other">
          <ul>
            <a [routerLink]="['/view-more', { categoryName: '', subCategory: 'BUSINESS' }]">
              <li>Business</li>
            </a>
            
            <a [routerLink]="['/view-more', { categoryName: '', subCategory: 'DEFENCE & SECURITY' }]">
              <li>Defence & Security</li>
            </a>
            
            <a [routerLink]="['/view-more', { categoryName: '', subCategory: 'ENTERTAINMENT' }]">
              <li>Entertainment</li>
            </a>
            
            <a [routerLink]="['/view-more', { categoryName: '', subCategory: 'Environment' }]">
              <li>Environment</li>
            </a>
            
            <a [routerLink]="['/view-more', { categoryName: '', subCategory: 'Religious' }]">
              <li>Religious</li>
            </a>
            
            <a [routerLink]="['/view-more', { categoryName: '', subCategory: 'History & Culture' }]">
              <li>History & Culture</li>
            </a>
            
            <a [routerLink]="['/view-more', { categoryName: '', subCategory: 'Tech & Education' }]">
              <li>Tech & Education</li>
            </a>
            
            <a [routerLink]="['/view-more', { categoryName: '', subCategory: 'Health & LifeStyle' }]">
              <li>Health & lifestyle</li>
            </a>
            
            <a [routerLink]="['/view-more', { categoryName: '', subCategory: 'SPORTS' }]">
              <li>Sports</li>
            </a>
            
            <a [routerLink]="['/view-more', { categoryName: '', subCategory: 'Travel' }]">
              <li>Travel</li>
            </a>
            
            <a [routerLink]="['/view-more', { categoryName: '', subCategory: 'Crime' }]">
              <li>Crime</li>
            </a>
            
          </ul>
        </div>
      </li>
    </div>
    <li>
      <input
        *ngIf="isSearchClicked"
        type="text"
        placeholder="   Search..."
        (click)="$event.stopPropagation()"
        (keyup.enter)="searchContent(searchText)"
        [(ngModel)]="searchText"
        (keyup)="searchContent(searchText)"
        class="search-box"
      />
      <i (click)="toggleSearch()" class="fa fa-search" aria-hidden="true"></i>
      <div class="search border-bottom" *ngIf="isSearchClicked && searchResult && searchResult.length > 0">
        <li
          class="border-bottom"
          *ngFor="let search of searchResult"
          (click)="gotoNewsDetail(search?._id, search?.title)"
        >
          {{ (search?.title | slice: 0 : 17) + '...' }}
        </li>
      </div>
    </li>
    <li >
      <button  type="button" class="subscribe" (click)="logout()">{{userName? userName : 'Subscribe'}} <mat-icon *ngIf="userName">power_settings_new</mat-icon></button>
      <div *ngIf="!userName" class="google_sign_in">
        <div id="g_id_onload"
          data-client_id="999744730871-1ufcmus6e1qre0df21e987gc3jdlluni.apps.googleusercontent.com"
          data-context="signin"
          data-ux_mode="popup"
          data-callback="handleCredentialResponse"
          data-auto_prompt="false">
      </div>

      <div class="g_id_signin"
        #googleSignInButton
          data-type="standard"
          data-shape="rectangular"
          data-theme="outline"
          data-text="signin"
          data-size="large"
          data-logo_alignment="left">
      </div>
      </div>
    </li>
  </ul>

  <span class="mobile_menu_bars" (click)="openMobileNavigationMenu()">
    <i class="fa fa-bars" aria-hidden="true"></i>
  </span>
</div>

<div class="mobile_navigation_menu" #mobileMenu style="overflow: scroll;">
  <div class="main_mobile_menu">
    <div class="mobile_menu_left_div"></div>

    <div class="mobile_menu_right_div">
      <span (click)="closeMobileNavigationMenu()">
        <i class="fa fa-times" aria-hidden="true"></i>
      </span>
    </div>
  </div>

  <ul class="p-4 vertical_header_menu">
    <li>
      <input
        type="text"
        placeholder="Search..."
        class="w-100"
        (click)="$event.stopPropagation()"
        (keyup.enter)="searchContent(searchText)"
        [(ngModel)]="searchText"
        (keyup)="searchContent(searchText)"
      />
    </li>
    <div class="search border-bottom" *ngIf="searchResult && searchResult.length > 0">
      <li
        class="border-bottom"
        *ngFor="let search of searchResult"
        (click)="gotoNewsDetail(search?._id, search?.title)"
      >
        {{ (search?.title | slice: 0 : 25) + '...' }}
      </li>
    </div>
    <br>
    <li class="mob-menu border-bottom" (click)="goto()" >Home</li>
    <li class="mob-menu border-bottom" (click)="gotoViewMoreMobile('INDIA')">India</li>
    <li class="mob-menu border-bottom" (click)="gotoViewMoreMobile('', 'FACT CHECK')">Fact Check</li>
    <li class="mob-menu border-bottom" (click)="toggleWorld()">World
      <i class="bi bi-caret-down-fill">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="10"
          height="10"
          fill="currentColor"
          class="bi bi-caret-down-fill"
          viewBox="0 0 16 16"
        >
          <path
            d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"
          />
        </svg>
      </i>
    </li>
    <div *ngIf="worldAccordionOpen">
      <ul>
        <li class="mob-menu border-bottom" (click)="gotoViewMoreMobile('', 'US')">US</li>
        <li class="mob-menu border-bottom" (click)="gotoViewMoreMobile('', 'UK')">UK</li>
        <li class="mob-menu border-bottom" (click)="gotoViewMoreMobile('', 'South Asia')">South Asia</li>
        <li class="mob-menu border-bottom" (click)="gotoViewMoreMobile('', 'Russia')">Russia</li>
        <li class="mob-menu border-bottom" (click)="gotoViewMoreMobile('', 'China')">China</li>
        <li class="mob-menu border-bottom" (click)="gotoViewMoreMobile('', 'Africa')">Africa</li>
        <li class="mob-menu border-bottom" (click)="gotoViewMoreMobile('', 'Europe')">Europe</li>
        <li class="mob-menu border-bottom" (click)="gotoViewMoreMobile('', 'Middle East')">Middle East</li>
        <li class="mob-menu border-bottom" (click)="gotoViewMoreMobile('', 'Rest of the World')">Rest Of the World</li>
      </ul>
    </div>
    <li class="mob-menu border-bottom" (click)="gotoViewMoreMobile('', 'Politics')">Politics</li>
    <li class="mob-menu border-bottom" (click)="toggleOpinion()">Options and Analysis
      <i class="bi bi-caret-down-fill">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="10"
          height="10"
          fill="currentColor"
          class="bi bi-caret-down-fill"
          viewBox="0 0 16 16"
        >
          <path
            d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"
          />
        </svg>
      </i>
    </li>
    <div *ngIf="opinionAccordionOpen">
      <ul>
        <li class="mob-menu border-bottom" (click)="gotoViewMoreMobile('', 'COLUMNS')">Columns</li>
        <li class="mob-menu border-bottom" (click)="gotoViewMoreMobile('', 'EDITORIALS')">Editorials</li>
      </ul>
    </div>
    <li class="mob-menu border-bottom" (click)="togglePublications()">Publications
      <i class="bi bi-caret-down-fill">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="10"
          height="10"
          fill="currentColor"
          class="bi bi-caret-down-fill"
          viewBox="0 0 16 16"
        >
          <path
            d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"
          />
        </svg>
      </i>
    </li>
    <div *ngIf="publicationAccordionOpen">
      <ul>
        <li class="mob-menu border-bottom" (click)="gotoViewMoreMobile('', 'Reports')">Reports</li>
        <li class="mob-menu border-bottom" (click)="gotoViewMoreMobile('', 'e-Magazines')">E-Magazines</li>
      </ul>
    </div>
    <li class="mob-menu border-bottom" (click)="toggleActivity()">Activities
      <i class="bi bi-caret-down-fill">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="10"
          height="10"
          fill="currentColor"
          class="bi bi-caret-down-fill"
          viewBox="0 0 16 16"
        >
          <path
            d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"
          />
        </svg>
      </i>
    </li>
    <div *ngIf="activityAccordionOpen">
      <ul>
        <li class="mob-menu border-bottom" (click)="gotoViewMoreMobile('', 'Seminars')">Seminars</li>
        <li class="mob-menu border-bottom" (click)="gotoViewMoreMobile('', 'Discussions')">Discussions</li>
        <li class="mob-menu border-bottom" (click)="gotoViewMoreMobile('', 'Conferences')">Conferences</li>
      </ul>
    </div>
    <li class="mob-menu border-bottom" (click)="otherActivity()">Others
      <i class="bi bi-caret-down-fill">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="10"
          height="10"
          fill="currentColor"
          class="bi bi-caret-down-fill"
          viewBox="0 0 16 16"
        >
          <path
            d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"
          />
        </svg>
      </i>
    </li>
    <div *ngIf="otherAccordionOpen">
      <ul>
        <li class="mob-menu border-bottom" (click)="gotoViewMoreMobile('', 'BUSINESS')">Business</li>
        <li class="mob-menu border-bottom" (click)="gotoViewMoreMobile('', 'DEFENCE & SECURITY')">Defence & Security</li>
        <li class="mob-menu border-bottom" (click)="gotoViewMoreMobile('', 'ENTERTAINMENT')">Entertainment</li>
        <li class="mob-menu border-bottom" (click)="gotoViewMoreMobile('', 'Environment')">Environment</li>
        <li class="mob-menu border-bottom" (click)="gotoViewMoreMobile('', 'Religious')">Religious</li>
        <li class="mob-menu border-bottom" (click)="gotoViewMoreMobile('', 'History & Culture')">History & Culture</li>
        <li class="mob-menu border-bottom" (click)="gotoViewMoreMobile('', 'Tech & Education')">Tech & Education</li>
        <li class="mob-menu border-bottom" (click)="gotoViewMoreMobile('', 'Health & lifestyle')">Health & lifestyle</li>
        <li class="mob-menu border-bottom" (click)="gotoViewMoreMobile('', 'SPORTS')">Sports</li>
        <li class="mob-menu border-bottom" (click)="gotoViewMoreMobile('', 'Travel')">Travel</li>
        <li class="mob-menu border-bottom" (click)="gotoViewMoreMobile('', 'Crime')">Crime</li>
      </ul>
    </div>
    <!-- <li>
      <i class="bi bi-search"></i
      ><svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        class="bi bi-search"
        viewBox="0 0 16 16"
      >
        <path
          d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0"
        />
      </svg>
    </li> -->
    <li style="display: flex;">
      <button  type="button" class="subscribe" (click)="logout()">{{userName? userName : 'Subscribe'}} <mat-icon *ngIf="userName">power_settings_new</mat-icon></button>
      <div *ngIf="!userName" style="position: absolute;">
        <div id="g_id_onload"
          data-client_id="999744730871-1ufcmus6e1qre0df21e987gc3jdlluni.apps.googleusercontent.com"
          data-context="signin"
          data-ux_mode="popup"
          data-callback="handleCredentialResponse"
          data-auto_prompt="false">
        </div>

        <div class="g_id_signin"
          #googleSignInButton
          data-type="standard"
          data-shape="rectangular"
          data-theme="outline"
          data-text="signin"
          data-size="large"
          data-logo_alignment="left">
        </div>
      </div>
    </li>
  </ul>
</div>
