import { NgModule } from '@angular/core'
import { BrowserModule, provideClientHydration } from '@angular/platform-browser'

import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { HeaderComponent } from './shared/component/header/header.component'
import { FooterComponent } from './shared/component/footer/footer.component'
import { LayoutModule } from './layout/layout.module'
import { BrowserAnimationsModule, provideAnimations } from '@angular/platform-browser/animations'
import { HTTP_INTERCEPTORS, HttpClientModule, provideHttpClient, withFetch, withInterceptorsFromDi } from '@angular/common/http'
import { MatSnackBarModule } from '@angular/material/snack-bar'
import { SouthAsianInterceptor } from './shared/services/southsianinterceptor'
import { provideServerRendering } from '@angular/platform-server'

@NgModule({
  declarations: [AppComponent],
  imports: [BrowserModule, AppRoutingModule, LayoutModule, BrowserAnimationsModule, HttpClientModule, MatSnackBarModule],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: SouthAsianInterceptor,
    multi: true
  }, provideClientHydration(), provideAnimations(), provideHttpClient(withInterceptorsFromDi(), withFetch())],
  bootstrap: [AppComponent],
})
export class AppModule { }
