import { Component } from '@angular/core'
import { HomeService } from 'src/app/shared/services/home.service'
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-view-profile',
  templateUrl: './view-profile.component.html',
  styleUrls: ['./view-profile.component.scss'],
  host: {ngSkipHydration: 'true'},
})
export class ViewProfileComponent {

  profileNews :any = []
  currentPage: number = 1; // Current page
  itemsPerPage: number = 5; // Number of items per page
  totalPages: number = 0; // Total number of pages
  pages: number[] = [];
  id:any
  constructor(
    public _homeService : HomeService,
    private activatedRoute: ActivatedRoute,
    public router: Router,
  ){}

  ngOnInit():void {
    this.activatedRoute.params.subscribe(params => {
      const id = params['_id'];
      this.id = id
      this.getProfileNews(id);
    });
  }


  getProfileNews(id:any) {
    this._homeService.getProfileWithNews(this.currentPage, this.itemsPerPage,id).subscribe({
      next: (res: any) => {
        if (res.success === true) {
          this.profileNews = res.data
          this.totalPages = Math.ceil(res.count / this.itemsPerPage);
          // Generate array of page numbers
          this.pages = Array.from({ length: this.totalPages }, (_, i) => i + 1);
        }
      },
      error: (err) => {
        console.log('error', err)
      },
    })
  }

  gotoNewsDetail(newsId: any, newsTitle: string) {
    this.router.navigate(['news-detail', this.formatTitleForUrl(newsTitle), newsId])
  }

  formatTitleForUrl(title: string): string {
    return title.replace(/\s+/g, '-').toLowerCase()
  }

  
  setPage(page: number): void {
    // Check if page is within valid range
    if (page >= 1 && page <= this.totalPages) {
      this.currentPage = page;
      this.getProfileNews(this.id)
    }
  }

  prevPage(): void {
    if (this.currentPage > 1) {
      this.currentPage--;
      this.getProfileNews(this.id)
    }
  }

  nextPage(): void {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
      this.getProfileNews(this.id)
    }
  }

}
