<div class="container center w-75">
  <!-- <p>{{ showData?.type }}</p> -->
  <h1 class="center text-capitalize mob-head">{{ showData?.title }}</h1>
  <P class="author topic mob-name">
    By <span>
      <a [routerLink]="['/view-profile', showData?.createdBy?._id]">
        {{ showData?.createdBy?.firstName }}
      </a>
    </span>
    &nbsp;
    <span>
      <a [routerLink]="['/view-profile', showData?.createdBy?._id]">
        {{ showData?.createdBy?.lastName }}
      </a>
    </span>
    
  </P>
  <div class="share-buttons-container d-flex justify-content-between justify-content-sm-between flex-column flex-sm-row">
    <p class="published mob-pub">Published : {{ showData?.createdAt | date: 'MMMM d, h:mma' }}</p>
    <div class="d-flex flex-wrap">
      <!-- [url]="'https://www.youtube.com/'" -->
        <share-buttons theme="material-dark" [include]="['facebook', 'twitter', 'linkedin', 'whatsapp']"  [autoSetMeta]="false" class="share-btn"></share-buttons>
    </div>
  </div>

 <div class="containter">
    <div class="news-slide">
      <div id="combinedCarouselControls" class="carousel slide" data-bs-ride="carousel">
        <div class="carousel-inner">
          <!-- Videos -->
          <div *ngFor="let videoUrl of showData?.videoUrl; let i = index">
            <div class="carousel-item" [ngClass]="{ active: i === 0 }">
              <youtube-player
                class="video"
                [videoId]="getVideoId(videoUrl)"
                [width]="1000"
                [height]="450"
              ></youtube-player>
            </div>
          </div>

          <!-- Images -->
          <div *ngFor="let img of showData?.images; let i = index">
            <div class="carousel-item" [ngClass]="{ active: i === 0 }">
              <img
                src="{{ img.imageUrl ? img.imageUrl : 'assets/image/no-image.png' }}"
                class="d-block w-100"
                alt="..."
              />
            </div>
          </div>
        </div>

        <!-- Combined Controls -->
        <button
          class="carousel-control-prev caro-ryt"
          type="button"
          data-bs-target="#combinedCarouselControls"
          data-bs-slide="prev"
        >
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button
          class="carousel-control-next caro-ryt"
          type="button"
          data-bs-target="#combinedCarouselControls"
          data-bs-slide="next"
        >
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div>
    </div>
  </div>
  <p class="image-below mob-img-desc">{{ showData?.imageDesc }}</p>
  <p class="content" [innerHTML]="showData?.content"></p>
  <!-- Button trigger modal -->
  <div *ngIf="showData?.pdf?.pdfUrl" class="mb-4">
    <button type="button" class="btn btn-outline-warning my-2" data-bs-toggle="modal" data-bs-target="#exampleModal">
      View PDF
     </button>
  </div>

<!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" style="max-width: 50rem">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">View PDF</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <!-- check for SSR -->
        <!-- <ngx-extended-pdf-viewer [src]=path [showSidebarButton]="false" [showRotateButton]="false" [showOpenFileButton]="false" [showPrintButton]="false" [showDownloadButton]="false" [showTextEditor]="false" [showDrawEditor]="false" [showSecondaryToolbarButton]="false" [showStampEditor]="false" [showScrollingButton]="true"></ngx-extended-pdf-viewer> -->
        <!-- <ngx-extended-pdf-viewer [src]="showData?.pdf?.pdfUrl" [showSidebarButton]="false" [showRotateButton]="false" [showOpenFileButton]="false" [showPrintButton]="false" [showDownloadButton]="false" [showTextEditor]="false" [showDrawEditor]="false" [showSecondaryToolbarButton]="false" [showStampEditor]="false" [showScrollingButton]="true" [httpHeaders]="httpHeaders"></ngx-extended-pdf-viewer> -->
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>
  <!-- <div class="container my-3 pdf">
    <ngx-extended-pdf-viewer [src]=path [showSidebarButton]="false" [showRotateButton]="false" [showOpenFileButton]="false" [showPrintButton]="false" [showDownloadButton]="false" [showTextEditor]="false" [showDrawEditor]="false" [showSecondaryToolbarButton]="false" [showStampEditor]="false" [showScrollingButton]="true"></ngx-extended-pdf-viewer>
  </div> -->
  <div>
    <div>
      <div class="d-flex">
        <div class="flex-shrink-0">
          <div class="comment">
            <img src="assets/image/user-img.avif" alt="..." />
          </div>
        </div>
        <div class="flex-grow-1 ms-3">
          <textarea rows="6" class="w-100" #comment placeholder="Type your text here"></textarea>
        </div>
      </div>
    </div>
    <div class="d-flex align-items-end flex-column">
      <button class="send-btn fw-bold" (click)="addComment()">SEND</button>
    </div>
    <div *ngFor="let comment of commentsList">
      <div>
        <div class="d-flex my-2">
          <div class="flex-shrink-0">
            <div class="comment">
              <img [src]="userId ? comment.avatar : 'assets/image/user-img.avif'" alt="..." />
            </div>
          </div>
          <div class="flex-grow-1 ms-3">
            <div class="d-flex justify-content-between">
              <p class="mt-2"><span class="fw-bold">{{comment.commentedBy}}</span>&nbsp; <span class="opacity-50">- {{comment.postDate}}</span></p>
              <p *ngIf="userId == comment.commentedById" class="thumb-up"> <span (click)="deleteCommentById(comment._id)"><i class="fa fa-trash" aria-hidden="true"></i></span></p>
            </div>
            <p>{{comment.comment}}</p>
          </div>
        </div>
      </div>
    </div>
      <nav aria-label="Page navigation" *ngIf="this.totalPages">
        <ul class="pagination justify-content-center mt-4">
          <li class="page-item" [class.disabled]="currentPage === 1">
            <a class="page-link" (click)="prevPage()" tabindex="-1" [attr.aria-disabled]="currentPage === 1 ? true : null">Previous</a>
          </li>
          <li class="page-item" *ngFor="let page of pages" [class.active]="page === currentPage">
            <a class="page-link" (click)="setPage(page)">{{ page }}</a>
          </li>
          <li class="page-item" [class.disabled]="currentPage === totalPages">
            <a class="page-link" (click)="nextPage()" [attr.aria-disabled]="currentPage === totalPages ? true : null">Next</a>
          </li>
        </ul>
      </nav>
    
  </div>
  <p class="fw-bold mob-related">RELATED ARTICLES</p>
  <a [routerLink]="['/news-detail',formatTitleForUrl(showData?.relatedNews?.title),showData?.relatedNews?._id]">
    <div class="d-flex my-3 topic" style="max-width: 50rem">
      <div class="flex-shrink-0">
        <div class="middle-image related-art">
          <img src="{{showData?.relatedNews?.images[0]?.imageUrl ? showData?.relatedNews?.images[0]?.imageUrl : 'assets/image/no-image.png'}}" alt="..." />
        </div>
      </div>
      <div class="flex-grow-1 ms-3 mob-related">
        <p class="fw-bold">
          {{showData?.relatedNews?.title}}
        </p>
        <p class="mob-content" [innerHTML]="(showData?.relatedNews?.content | slice: 0: 200) + (showData?.relatedNews?.content.length > 200 ? '...' : ''  )"></p>
      </div>
    </div>
  </a>
  <div class="">
    <p class="fw-bold mt-5">MOST VIEWED</p>
  </div>
  <div>
    <div class="row mb-5">
      <div class="col-lg-6 col-sm-12 col-md-6 my-2 topic mob-related" *ngFor="let mostView of showData?.mostViewed; let i = index">
        <a [routerLink]="['/news-detail',formatTitleForUrl(mostView?.title),mostView?._id]">
          <div class="d-flex">
            <div class="flex-shrink-0 fw-bold">{{i + 1}}.</div>
            <div class="flex-grow-1 ms-3">
              {{mostView?.title}}
            </div>
          </div>
        </a>
      </div>
    </div>
  </div>
</div>