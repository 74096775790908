import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'

import { LayoutRoutingModule } from './layout-routing.module'
import { SharedModule } from '../shared/shared.module'
import { HomeComponent } from './home/home.component'
import { LayoutComponent } from './layout.component'
import { RouterModule } from '@angular/router'
import { MatTabsModule } from '@angular/material/tabs'
import { MatButtonToggleModule } from '@angular/material/button-toggle'
import { NewsDetailComponent } from './news-detail/news-detail.component'
import { ViewMoreComponent } from './view-more/view-more.component'
import { MatIconModule } from '@angular/material/icon'
import { YouTubePlayerModule } from '@angular/youtube-player'
import { ViewProfileComponent } from './view-profile/view-profile.component'
import { NgxExtendedPdfViewerModule} from 'ngx-extended-pdf-viewer'
import { MatDialogModule } from '@angular/material/dialog'
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons'
import { ShareIconsModule } from 'ngx-sharebuttons/icons'
import { ReactiveFormsModule } from '@angular/forms'
import { MatFormFieldModule } from "@angular/material/form-field";
import { AboutUsComponent } from './about-us/about-us.component';
import { LoaderComponent } from './loader/loader.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { provideClientHydration } from '@angular/platform-browser'


@NgModule({
  declarations: [HomeComponent, LayoutComponent, NewsDetailComponent, ViewMoreComponent, ViewProfileComponent, AboutUsComponent, LoaderComponent, PrivacyPolicyComponent],
  imports: [
    CommonModule,
    LayoutRoutingModule,
    SharedModule,
    RouterModule,
    MatTabsModule,
    MatButtonToggleModule,
    MatIconModule,
    YouTubePlayerModule,
    NgxExtendedPdfViewerModule,
    MatDialogModule,
    ShareIconsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    ShareButtonsModule.withConfig({
      debug: true
    }),
  ],
  providers:[provideClientHydration()]
})
export class LayoutModule {
  constructor() {}
}
