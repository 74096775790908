import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Meta, Title } from '@angular/platform-browser'
import { environment } from 'src/environments/environment'
import { Location } from '@angular/common'

@Injectable({
  providedIn: 'root',
})
export class HomeService {
  constructor(
    private http: HttpClient,
    private titleService: Title,
    private meta: Meta,
    private location: Location,
  ) {}

  API_URl = environment.serverUrl
  // baseUrl = this.API_URl + 'admin'

  getAllNews() {
    return this.http.get<any>(`${this.API_URl}news/homePageNews`)
  }

  addNewsLetter(obj:any){
    return this.http.post<any>(`${this.API_URl}newsLetter`,obj);
  }

  getFlashNews() {
    return this.http.get<any>(`${this.API_URl}news/webNews?pageNumber=1&pageSize=20&categoryName=News Flash`)
  }

  getProfileWithNews(pageNumber:any, pageSize:any,_id:any) {
    return this.http.get<any>(`${this.API_URl}news/webNews?pageNumber=${pageNumber}&pageSize=${pageSize}&createdBy=${_id}`)
  }

  getNewsById(_id?: any, type?: string, categoryName?: string) {
    let queryParams = ' '

    if (_id) {
      queryParams += `&_id=${_id}`
    }

    if (type) {
      queryParams += `&type=${type}`
    }

    if (categoryName) {
      queryParams += `&categoryName=${categoryName}`
    }

    // Make the HTTP request with the constructed URL
    return this.http.get<any>(`${this.API_URl}news/webNews?pageNumber=1&pageSize=1&${queryParams}`)
  }

  getNewsByTypeAndCategory( pageNumber:any, pageSize:any,categoryName?: string, subCategory?: string,) {
    let apiUrl = `${this.API_URl}news/webNews?pageNumber=${pageNumber}&pageSize=${pageSize}`

    const params: any = {}

    // if (type) {
    //   params.type = type
    // }

    if (categoryName) {
      params.categoryName = categoryName
    }

    if (subCategory) {
      params.subCategory = subCategory
    }

    const httpParams = new HttpParams({ fromObject: params })

    apiUrl += `&${httpParams.toString()}`

    return this.http.get<any>(apiUrl)
  }

  getTrendingNews() {
    return this.http.get<any>(`${this.API_URl}news/trendingPost`)
  }

  getSuggestion(item: any) {
    return this.http.get<any>(`${this.API_URl}news/newsSuggestions?searchTerm=${item}`)
  }

  setTitleAndMetaTags(title: string, description: string, keywords: string, img:any): void {
    this.titleService.setTitle(title)

    this.meta.updateTag({ name: 'description', content: description })
    this.meta.updateTag({ name: 'keywords', content: keywords })
    this.meta.updateTag({ property: 'og:title', content: title });
    this.meta.updateTag({ property: 'og:description', content: description });
    this.meta.updateTag({ property: 'og:image', content:img.imageUrl });
    this.meta.updateTag({ property: 'og:image:secure_url', content:img.imageUrl });
    
    // this.meta.updateTag({ property: 'twitter:card', content: 'summary' });
    this.meta.updateTag({ name: 'twitter:title', content: title });
    this.meta.updateTag({ name: 'twitter:text:title', content: title });
    // this.meta.updateTag({ property: 'twitter:description', content: description });
    this.meta.updateTag({ name: 'twitter:description', content: description });
    // this.meta.updateTag({ property: 'twitter:image', content: 'https://static.everypixel.com/ep-pixabay/0329/8099/0858/84037/3298099085884037069-head.png' });
    // this.meta.updateTag({ name: 'twitter:image', content:img.imageUrl });
    this.meta.updateTag({ name: 'twitter:image:secure', content:img.imageUrl });
    // this.meta.updateTag({ property: 'twitter:width', content:"1200" });
    // this.meta.updateTag({ property: 'twitter:height', content: "630" });
    // this.meta.updateTag({ property: 'og:url', content: "https://southasiandigest.com/" });
    // this.meta.updateTag({ property: 'og:url', content: url });
  }

  updateUrlWithSlug(id: string, slug: string): void {
    const currentUrl = this.location.path()
    const newUrl = currentUrl.replace(id, slug)

    this.location.replaceState(newUrl)
  }
  signUpWebUser(user:any){
    return this.http.post<any>(`${this.API_URl}webUser`, user)
  }

  addComment(comment: any){
    return this.http.put<any>(`${this.API_URl}comments`, comment)
  }

  getUserById(id:string){
    return this.http.get<any>(`${this.API_URl}webUser/${id}`)
  }

  getCommentsById(id:string, pageNumber: number, pageSize: number){
    return this.http.get<any>(`${this.API_URl}comments/${id}?pageNumber=${pageNumber}&size=${pageSize}`)
  }

  deleteCommentByCommentId(newsId:string, commentId:string){
    return this.http.delete<any>(`${this.API_URl}comments/${newsId}?commentId=${commentId}`)
  }
}
