import { Component, ElementRef, Renderer2, ViewEncapsulation } from '@angular/core'
import { DomSanitizer, SafeHtml } from '@angular/platform-browser'
import { Router } from '@angular/router'
import { HomeService } from 'src/app/shared/services/home.service'
import { LoaderService } from 'src/app/shared/services/loader.service'
import { FormBuilder, Validators } from '@angular/forms'
import Swal from 'sweetalert2'

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {ngSkipHydration: 'true'},
})
export class HomeComponent {
  newsList: any
  indMainNews: any = []
  mainIndiaNews: any = []
  upperIndiaNews: any = []
  lowerIndiaNews: any = []
  worldMainNews: any = []
  opinionAndAnalysis: any = []
  factCheck: any = []
  selectedCategory: string | null = null
  selectedVideoUrl: string = ''

  historyImage: string = ''
  religiousImage: string = ''
  cultureImage: string = ''
  selectedVedioTitle: string = ''
  selectedVedioContent: string = ''
  selectedVideoId: string = ''
  flashNews: any = []
  profileNews: any = []
  // playerWidth: number = 500;
  userDetails: any
  userId: string = ''
  newsLetterForm: any;
  signedUp: boolean = false;
  userName: string = ''
  isLoading: boolean = true;

  constructor(
    public _homeService: HomeService,
    private sanitizer: DomSanitizer,
    public router: Router,
    private el: ElementRef,
    private renderer: Renderer2,
    public loaderService: LoaderService,
    private _fb : FormBuilder,
  ) {
    this.newsLetterForm = this._fb.group({
      email: ['', [Validators.required, Validators.email]]
    })



  }

  ngOnInit(): void {

    this.getNews()
    const defaultCategory = 'historyAndCulture'
    this.selectCategory(defaultCategory)
    this.getFlashNews()
    this.loaderService.userName.subscribe(name => {
      this.userName = name
    })
    this.userId = localStorage.getItem('SAWebUserId') || '';
    if (!this.userId) this.signUp()
  }

  // @HostListener('window:resize', ['$event'])
  // onResize(event: Event): void {
  //   this.adjustPlayerWidth();
  // }

  // private adjustPlayerWidth(): void {
  //   const screenWidth = window.innerWidth;

  //   // Adjust width based on your desired logic
  //   this.playerWidth = screenWidth <= 768 ? 350 : 500; // Adjust the values as needed

  //   // Update the width using Renderer2
  //   this.renderer.setStyle(
  //     this.el.nativeElement.querySelector('.video-size'),
  //     'width',
  //     `${this.playerWidth}px`
  //   );
  // }

  getFlashNews() {
    this._homeService.getFlashNews().subscribe({
      next: (res: any) => {
        if (res.success === true) {
          this.flashNews = res.data
        }
      },
      error: (err) => {
        console.log('error', err)
      },
    })
  }

  gotoProfile(id: any) {
    this.router.navigate(['view-profile', id])
  }

  signUp() {
    this.userDetails = localStorage.getItem('loggedInUser') || ''
    if (this.userDetails) {
      const user = JSON.parse(this.userDetails)
      let userDto = {
        firstName: user.given_name,
        lastName: user.family_name,
        email: user.email,
        avatar: user.picture
      }
      this._homeService.signUpWebUser(userDto).subscribe({
        next: (res: any) => {
          if (res.success) {
            this.userDetails = res.data;
            this.userName = res.data.firstName
            this.loaderService.userName.next(res.data.firstName)
            localStorage.removeItem('loggedInUser')
            localStorage.setItem('SAWebUserId', this.userDetails._id)
            localStorage.setItem('token', this.userDetails.token);
          }
        }
      })
    }
  }
  getNews() {
    this.isLoading = true;
    this._homeService.getAllNews().subscribe({
      next: (res: any) => {
        if (res.success === true) {
          this.newsList = res.data
          this.isLoading = false;
          // will get india 3 news
          // if(this.newsList?.news?.INDIA) {
          //   this.newsList.news.INDIA  =   (this.newsList?.news?.INDIA as Array<any>).splice(0, 3)
          // }
          // will get all main-news=true of India
          // this.indMainNews = this.newsList.news.INDIA.filter((item :any) =>{
          //   return item.mainNews == true
          // })
          // will get all main-news = true of World
          // this.worldMainNews = this.newsList.news.WORLD.filter((item :any) =>{
          //   return item.mainNews == true
          // })
          // console.log(this.worldMainNews);

          const getHistoryNews = this.newsList?.other?.historyAndCulture

          if ((getHistoryNews as Array<any>).length) {
            if (((getHistoryNews as Array<any>)[0].videoUrl as Array<any>).length) {
              const videoUrl = (getHistoryNews as Array<any>)[0].videoUrl as Array<any>

              this.historyImage = videoUrl.length ? ((videoUrl[0] as Array<any>).length ? videoUrl[0] : '') : ''
            }


          }

          const objValues = Object.values(this.newsList.opinionAndAnalysis)
          this.opinionAndAnalysis = objValues.map((propertyArray: any) => [...propertyArray])
        }
      },
      error: (err) => {
        this.isLoading = false;
        console.log('error', err)
      },
    })
  }

  selectCategory(category: string): void {
    this.selectedVedioTitle = ''
    this.selectedVedioContent = ''
    this.selectedCategory = category
    this.selectedVideoUrl = this.newsList?.other?.[category][0]?.videoUrl[0] || ''
    this.historyImage = this.selectedVideoUrl
  }

  getSanitizedContent(): SafeHtml {
    const content = this.newsList.news.INDIA.TECHNOLOGY[0].content
    return this.sanitizer.bypassSecurityTrustHtml(content)
  }

  gotoNewsDetail(newsId: any, newsTitle: string) {
    this.router.navigate(['news-detail', this.formatTitleForUrl(newsTitle), newsId])
  }

  formatTitleForUrl(title: string): string {
    if(title)return title.replace(/\s+/g, '-').toLowerCase()
    return ''
  }

  gotoViewMore(categoryName?: string, subCategory?: string) {
    this.router.navigate([
      'view-more',
      { categoryName: categoryName ? categoryName : '', subCategory: subCategory ? subCategory : '' },
    ])
  }

  changeVideo(history: any) {
    this.selectedVedioTitle = history.title
    this.selectedVedioContent = history.content
    this.selectedVideoId = history._id
    this.historyImage = (history.videoUrl as Array<any>).length
      ? (history.videoUrl as Array<any> as Array<any>).length
        ? (history.videoUrl as Array<any> as Array<any>)[0]
        : ''
      : ''
  }

  getVideoId(url: string): string {
    const urlParams = new URLSearchParams(new URL(url).search)
    return urlParams.get('v') || ''
  }

  onFormSubmit() {
    if (this.newsLetterForm.valid) {
      let obj = {
        email: this.newsLetterForm.controls.email.value,
      }
      this._homeService.addNewsLetter(obj).subscribe({
        next: (res: any) => {
          if (res.success == true) {
            this.newsLetterForm.reset();
            Swal.fire({
              title: 'Sign-up Successfully',
              icon: 'success'
            })
          }
        },
        error: (err: any) => {
          Swal.fire({
            title: 'Email already Registered',
            icon: 'error'
          })
        }
      });
    }
  }

  ngOnDestroy(): void {
    // this.router.events.subscribe().unsubscribe();
  }
}
